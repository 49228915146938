<template>

  <div class="main home">

    <div class="header__main ">
        <div class="main-width">
            <div class="header__body">
                <h1 class="header__title"> {{$t('default.search_system')}} </h1>
                <form class="seach__area " @submit.prevent="searchKeywords">
                    <input type="text" class="form-control" v-model="keywords" name="keywords" id="keywords" :placeholder="$t('default.search_txt')">
                    <select class="form-control" v-model="searchOption">
                        <option value="plans">{{$t('search.plan')}}</option>
                        <option value="lots">{{$t('navigation.lot')}}</option>
                        <option value="tenders">{{$t('navigation.tender')}}</option>
                        <option value="contracts">{{$t('navigation.contracts')}}</option>
                    </select>
                    <button type="submit" class="btn " :disabled="searchOption === 0"> {{$t('search.search_btn')}} </button>
                </form>
                <p> {{$t('default.goszakup_info')}} </p>
            </div>
        </div>
    </div>
    <section v-show="false" class="main-width">
        <div class="row no-gutters">
            <div class="col-12 col-md-4 ">
                <div class="anim-block">
                    <h2>Закупки <br> для бизнеса</h2>
                    <p>
                        Узнайте по каким ценам покупает товары государство, недропользователи.
                    </p>
                    <a href="#" class="anim__btn">Перейти</a>
                    <div class="anim__image">
                        <svg width="268" height="243" viewBox="0 0 268 243" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="268"
                                height="243">
                                <rect width="268" height="243" transform="matrix(-1 0 0 1 268 0)" fill="#C4C4C4" />
                            </mask>
                            <mask="url(#mask0)">
                                <path
                                    d="M248.971 61.4418L143.761 0.716999C142.439 -0.0265704 141.199 -0.191788 140.125 0.221306L139.959 0.303905H139.877L136.488 1.95628L140.207 3.77386V3.93915V90.3584C140.207 94.6546 142.852 99.6943 146.158 101.595L248.558 160.667L249.136 165.459L252.855 163.641C254.095 162.898 254.839 161.245 254.839 158.932V72.5128C255.004 68.3818 252.277 63.3421 248.971 61.4418Z"
                                    fill="#553DB4" />
                                <path
                                    d="M248.322 61.6478L143.719 0.719403C142.405 -0.0266594 141.172 -0.192431 140.104 0.222048L139.939 0.304924H139.857L136.488 1.96284L140.186 3.78651V3.95236V61.0675L187.762 99.0338L252.43 66.2071C251.444 64.3005 249.965 62.5597 248.322 61.6478Z"
                                    fill="#553DB4" />
                                <path
                                    d="M250.825 62.8027L240.354 67.3244L248.228 73.6723L254.846 70.8027C254.26 67.7592 252.752 64.8027 250.825 62.8027Z"
                                    fill="#553DB4" fill-opacity="0.46" />
                                <path
                                    d="M245.271 164.742L140.027 104.447C136.72 102.56 134.074 97.5556 134.074 93.2898V7.48104C134.074 3.21521 136.72 1.24643 140.027 3.13324L245.271 63.429C248.578 65.3158 251.224 70.3199 251.224 74.5858V160.394C251.224 164.66 248.578 166.629 245.271 164.742Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M242.169 158.765L144.335 102.263C141.29 100.534 138.904 95.9212 138.904 91.9677V13.1455C138.904 9.19204 141.373 7.38001 144.335 9.10965L242.169 65.6113C245.214 67.3409 247.6 71.9533 247.6 75.9067V154.729C247.6 158.682 245.132 160.494 242.169 158.765Z"
                                    fill="white" />
                                <path
                                    d="M242.169 158.765L144.335 102.263C141.29 100.534 138.904 95.9212 138.904 91.9677V13.1455C138.904 9.19204 141.373 7.38001 144.335 9.10965L242.169 65.6113C245.214 67.3409 247.6 71.9533 247.6 75.9067V154.729C247.6 158.682 245.132 160.494 242.169 158.765Z"
                                    fill="white" />
                                <path
                                    d="M246.309 167.931L232.224 166.036L141.209 113.442C138.327 111.794 133.22 111.959 129.843 113.937L65.3502 151.197L56.7842 150.043V154.907C56.7018 156.061 57.3607 157.133 58.7609 157.875L161.966 217.474C164.848 219.123 169.955 218.958 173.332 216.98L243.59 176.422C245.485 175.351 246.391 173.949 246.391 172.63V167.931H246.309Z"
                                    fill="#553DB4" />
                                <path
                                    d="M178.758 134.165L172.212 130.435H100.701L65.402 150.828L56.7842 149.667V154.559C56.7014 155.719 57.3643 156.797 58.773 157.543L162.6 217.48C164.174 218.392 166.329 218.724 168.483 218.558L178.758 134.165Z"
                                    fill="#553DB4" />
                                <path
                                    d="M163.059 217.938C165.866 219.005 169.83 218.759 172.72 217.363V206.522H163.059V217.938Z"
                                    fill="#553DB4" fill-opacity="0.46" />
                                <path
                                    d="M161.965 212.607L58.7334 152.803C55.8498 151.149 56.1793 148.171 59.5572 146.186L129.834 105.489C133.212 103.504 138.32 103.339 141.203 104.993L244.435 164.797C247.318 166.451 246.989 169.429 243.611 171.414L173.334 212.111C169.957 214.096 164.849 214.344 161.965 212.607Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M243.617 170.123L173.344 210.362C169.967 212.325 164.859 212.489 161.976 210.853L58.7503 151.721C57.7617 151.148 57.185 150.494 56.9379 149.758C56.4436 151.148 57.1026 152.539 58.7503 153.52L161.976 212.652C164.859 214.288 169.967 214.124 173.344 212.161L243.617 171.922C245.923 170.614 246.747 168.896 246.253 167.342C245.923 168.324 245.017 169.305 243.617 170.123Z"
                                    fill="white" />
                                <path
                                    d="M102.71 133.941L98.1072 131.154C97.7035 130.9 97.7843 130.562 98.188 130.309L103.598 127.015C104.002 126.761 104.648 126.761 105.051 126.93L109.654 129.718C110.057 129.971 109.977 130.309 109.573 130.562L104.163 133.857C103.759 134.11 103.113 134.11 102.71 133.941Z"
                                    fill="#553DB4" />
                                <path
                                    d="M109.956 137.529L105.353 134.755C104.95 134.503 105.03 134.166 105.434 133.914L110.844 130.636C111.248 130.384 111.894 130.384 112.297 130.552L116.9 133.326C117.304 133.578 117.223 133.914 116.819 134.166L111.409 137.445C110.925 137.697 110.279 137.781 109.956 137.529Z"
                                    fill="#553DB4" />
                                <path
                                    d="M117.202 141.207L112.599 138.884C112.196 138.673 112.276 138.392 112.68 138.18L118.09 135.435C118.494 135.224 119.14 135.224 119.543 135.365L124.146 137.688C124.55 137.899 124.469 138.18 124.065 138.392L118.655 141.137C118.251 141.348 117.606 141.348 117.202 141.207Z"
                                    fill="#553DB4" />
                                <path
                                    d="M127.25 138.723L122.283 135.704C121.847 135.43 121.934 135.064 122.37 134.789L127.25 131.862C127.686 131.587 128.383 131.587 128.819 131.77L133.787 134.789C134.223 135.064 134.136 135.43 133.7 135.704L128.819 138.632C128.383 138.906 127.686 138.998 127.25 138.723Z"
                                    fill="#553DB4" />
                                <path
                                    d="M134.497 142.405L129.529 139.876C129.093 139.646 129.18 139.34 129.616 139.11L134.497 136.657C134.932 136.427 135.629 136.427 136.065 136.58L141.033 139.11C141.469 139.34 141.382 139.646 140.946 139.876L136.065 142.329C135.542 142.559 134.845 142.559 134.497 142.405Z"
                                    fill="#553DB4" />
                                <path
                                    d="M141.743 145.997L136.775 143.481C136.339 143.253 136.426 142.948 136.862 142.719L141.743 140.279C142.178 140.05 142.876 140.05 143.311 140.203L148.279 142.719C148.715 142.948 148.628 143.253 148.192 143.481L143.311 145.921C142.876 146.15 142.178 146.226 141.743 145.997Z"
                                    fill="#553DB4" />
                                <path
                                    d="M148.989 150.86L144.021 148.33C143.585 148.1 143.672 147.794 144.108 147.564L148.989 145.111C149.424 144.881 150.122 144.881 150.557 145.035L155.525 147.564C155.961 147.794 155.874 148.1 155.438 148.33L150.557 150.783C150.034 151.013 149.337 151.013 148.989 150.86Z"
                                    fill="#553DB4" />
                                <path
                                    d="M156.235 154.45L151.267 151.934C150.831 151.706 150.918 151.401 151.354 151.172L156.235 148.732C156.67 148.504 157.368 148.504 157.804 148.656L162.771 151.172C163.207 151.401 163.12 151.706 162.684 151.934L157.804 154.374C157.368 154.603 156.67 154.679 156.235 154.45Z"
                                    fill="#553DB4" />
                                <path
                                    d="M163.481 159.292L158.513 156.257C158.077 155.981 158.164 155.613 158.6 155.337L163.481 152.394C163.917 152.118 164.614 152.118 165.05 152.302L170.017 155.337C170.453 155.613 170.366 155.981 169.93 156.257L165.05 159.2C164.527 159.476 163.829 159.476 163.481 159.292Z"
                                    fill="#553DB4" />
                                <path
                                    d="M170.729 162.906L165.761 160.39C165.325 160.162 165.412 159.857 165.848 159.628L170.729 157.188C171.165 156.96 171.862 156.96 172.298 157.112L177.265 159.628C177.701 159.857 177.614 160.162 177.178 160.39L172.298 162.83C171.862 163.059 171.165 163.135 170.729 162.906Z"
                                    fill="#553DB4" />
                                <path
                                    d="M177.975 167.745L173.007 164.71C172.571 164.434 172.659 164.066 173.094 163.79L177.975 160.847C178.411 160.571 179.108 160.571 179.544 160.755L184.512 163.79C184.947 164.066 184.86 164.434 184.424 164.71L179.544 167.653C179.021 167.929 178.324 167.929 177.975 167.745Z"
                                    fill="#553DB4" />
                                <path
                                    d="M185.221 171.336L180.253 168.831C179.818 168.603 179.905 168.299 180.34 168.071L185.221 165.642C185.657 165.414 186.354 165.414 186.79 165.566L191.758 168.071C192.193 168.299 192.106 168.603 191.67 168.831L186.79 171.26C186.354 171.564 185.657 171.564 185.221 171.336Z"
                                    fill="#553DB4" />
                                <path
                                    d="M192.467 176.2L187.499 173.165C187.064 172.889 187.151 172.521 187.587 172.245L192.467 169.302C192.903 169.026 193.6 169.026 194.036 169.21L199.004 172.245C199.439 172.521 199.352 172.889 198.916 173.165L194.036 176.108C193.513 176.384 192.816 176.384 192.467 176.2Z"
                                    fill="#553DB4" />
                                <path
                                    d="M138.12 136.335L133.152 133.819C132.716 133.591 132.803 133.286 133.239 133.057L138.12 130.617C138.555 130.388 139.252 130.388 139.688 130.541L144.656 133.057C145.092 133.286 145.005 133.591 144.569 133.819L139.688 136.259C139.252 136.488 138.555 136.564 138.12 136.335Z"
                                    fill="#553DB4" />
                                <path
                                    d="M130.871 131.536L125.904 129.007C125.468 128.777 125.555 128.471 125.991 128.241L130.871 125.788C131.307 125.558 132.004 125.558 132.44 125.711L137.408 128.241C137.844 128.471 137.757 128.777 137.321 129.007L132.44 131.46C132.004 131.69 131.307 131.69 130.871 131.536Z"
                                    fill="#553DB4" />
                                <path
                                    d="M123.204 127.841L113.795 122.037C113.409 121.788 113.486 121.457 113.872 121.208L118.191 118.555C118.576 118.306 119.193 118.306 119.579 118.472L128.988 124.276C129.374 124.525 129.296 124.856 128.911 125.105L124.592 127.758C124.206 128.09 123.589 128.09 123.204 127.841Z"
                                    fill="#553DB4" />
                                <path
                                    d="M128.474 123.04L119.866 118.371C119.431 118.125 119.518 117.798 119.953 117.552L124.822 114.931C125.257 114.685 125.952 114.685 126.387 114.849L134.995 119.518C135.429 119.764 135.343 120.091 134.908 120.337L130.039 122.958C129.517 123.204 128.821 123.286 128.474 123.04Z"
                                    fill="#553DB4" />
                                <path
                                    d="M145.366 139.99L140.398 137.461C139.962 137.231 140.049 136.925 140.485 136.695L145.366 134.242C145.801 134.012 146.499 134.012 146.934 134.165L151.902 136.695C152.338 136.925 152.251 137.231 151.815 137.461L146.934 139.914C146.411 140.144 145.714 140.144 145.366 139.99Z"
                                    fill="#553DB4" />
                                <path
                                    d="M152.612 144.761L147.644 141.742C147.208 141.468 147.295 141.102 147.731 140.827L152.612 137.9C153.047 137.625 153.745 137.625 154.18 137.808L159.148 140.827C159.584 141.102 159.497 141.468 159.061 141.742L154.18 144.67C153.745 144.944 153.047 145.036 152.612 144.761Z"
                                    fill="#553DB4" />
                                <path
                                    d="M159.858 148.444L154.89 145.914C154.454 145.684 154.541 145.378 154.977 145.148L159.858 142.695C160.294 142.465 160.991 142.465 161.427 142.619L166.394 145.148C166.83 145.378 166.743 145.684 166.307 145.914L161.427 148.367C160.904 148.597 160.206 148.597 159.858 148.444Z"
                                    fill="#553DB4" />
                                <path
                                    d="M167.106 153.216L162.138 150.197C161.702 149.923 161.789 149.557 162.225 149.282L167.106 146.355C167.542 146.08 168.239 146.08 168.675 146.263L173.642 149.282C174.078 149.557 173.991 149.923 173.555 150.197L168.675 153.125C168.239 153.399 167.542 153.491 167.106 153.216Z"
                                    fill="#553DB4" />
                                <path
                                    d="M174.352 156.898L169.384 154.368C168.948 154.138 169.036 153.832 169.471 153.602L174.352 151.149C174.788 150.919 175.485 150.919 175.921 151.073L180.888 153.602C181.324 153.832 181.237 154.138 180.801 154.368L175.921 156.821C175.398 157.051 174.701 157.051 174.352 156.898Z"
                                    fill="#553DB4" />
                                <path
                                    d="M181.073 161.64L176.602 158.634C176.209 158.36 176.288 157.996 176.68 157.723L181.073 154.807C181.465 154.534 182.092 154.534 182.484 154.716L186.955 157.723C187.348 157.996 187.269 158.36 186.877 158.634L182.484 161.549C182.092 161.914 181.465 161.914 181.073 161.64Z"
                                    fill="#553DB4" />
                                <path
                                    d="M188.319 165.353L183.848 162.823C183.456 162.594 183.534 162.287 183.926 162.057L188.319 159.604C188.711 159.374 189.338 159.374 189.731 159.528L194.202 162.057C194.594 162.287 194.515 162.594 194.123 162.823L189.731 165.276C189.26 165.506 188.632 165.506 188.319 165.353Z"
                                    fill="#553DB4" />
                                <path
                                    d="M195.565 170.095L191.094 167.089C190.702 166.815 190.78 166.451 191.172 166.178L195.565 163.262C195.957 162.989 196.584 162.989 196.977 163.171L201.448 166.178C201.84 166.451 201.761 166.815 201.369 167.089L196.977 170.004C196.584 170.369 195.957 170.369 195.565 170.095Z"
                                    fill="#553DB4" />
                                <path
                                    d="M202.811 173.806L198.34 171.277C197.948 171.047 198.026 170.74 198.418 170.51L202.811 168.057C203.203 167.828 203.831 167.828 204.223 167.981L208.694 170.51C209.086 170.74 209.008 171.047 208.615 171.277L204.223 173.729C203.752 173.959 203.125 173.959 202.811 173.806Z"
                                    fill="#553DB4" />
                                <path
                                    d="M215.799 181.045L205.6 175.171C205.189 174.923 205.271 174.592 205.683 174.343L210.289 171.696C210.7 171.448 211.358 171.448 211.769 171.613L221.968 177.487C222.379 177.735 222.297 178.066 221.886 178.315L217.28 180.962C216.786 181.21 216.128 181.21 215.799 181.045Z"
                                    fill="#553DB4" />
                                <path
                                    d="M230.106 172.536L221.307 167.775C220.884 167.537 220.969 167.219 221.392 166.981L226.13 164.442C226.553 164.204 227.23 164.204 227.653 164.363L236.452 169.124C236.875 169.362 236.79 169.68 236.367 169.918L231.629 172.457C231.122 172.774 230.445 172.774 230.106 172.536Z"
                                    fill="#553DB4" />
                                <path
                                    d="M158.125 139.969L153.654 136.934C153.262 136.658 153.341 136.29 153.733 136.014L158.125 133.071C158.517 132.795 159.145 132.795 159.537 132.979L164.008 136.014C164.4 136.29 164.322 136.658 163.93 136.934L159.537 139.877C159.067 140.153 158.517 140.153 158.125 139.969Z"
                                    fill="#553DB4" />
                                <path
                                    d="M150.198 135.128L145.23 132.612C144.794 132.383 144.881 132.079 145.317 131.85L150.198 129.41C150.633 129.181 151.331 129.181 151.766 129.334L156.734 131.85C157.17 132.079 157.083 132.383 156.647 132.612L151.766 135.052C151.331 135.281 150.633 135.357 150.198 135.128Z"
                                    fill="#553DB4" />
                                <path
                                    d="M142.95 131.476L137.982 128.457C137.546 128.183 137.633 127.817 138.069 127.542L142.95 124.615C143.385 124.34 144.083 124.34 144.518 124.523L149.486 127.542C149.922 127.817 149.835 128.183 149.399 128.457L144.518 131.385C143.995 131.659 143.298 131.751 142.95 131.476Z"
                                    fill="#553DB4" />
                                <path
                                    d="M135.703 126.649L130.736 124.143C130.3 123.915 130.387 123.612 130.823 123.384L135.703 120.954C136.139 120.726 136.837 120.726 137.272 120.878L142.24 123.384C142.676 123.612 142.589 123.915 142.153 124.143L137.272 126.573C136.749 126.877 136.052 126.877 135.703 126.649Z"
                                    fill="#553DB4" />
                                <path
                                    d="M165.371 143.557L160.9 141.051C160.508 140.824 160.587 140.52 160.979 140.292L165.371 137.862C165.764 137.635 166.391 137.635 166.783 137.786L171.254 140.292C171.647 140.52 171.568 140.824 171.176 141.051L166.783 143.481C166.313 143.785 165.685 143.785 165.371 143.557Z"
                                    fill="#553DB4" />
                                <path
                                    d="M172.617 148.423L168.147 145.388C167.754 145.112 167.833 144.744 168.225 144.468L172.617 141.525C173.01 141.249 173.637 141.249 174.029 141.433L178.5 144.468C178.893 144.744 178.814 145.112 178.422 145.388L174.029 148.331C173.637 148.607 173.01 148.607 172.617 148.423Z"
                                    fill="#553DB4" />
                                <path
                                    d="M179.866 152.012L175.395 149.506C175.002 149.279 175.081 148.975 175.473 148.747L179.866 146.317C180.258 146.09 180.885 146.09 181.277 146.242L185.748 148.747C186.141 148.975 186.062 149.279 185.67 149.506L181.277 151.936C180.807 152.24 180.179 152.24 179.866 152.012Z"
                                    fill="#553DB4" />
                                <path
                                    d="M186.428 155.69L181.46 153.16C181.025 152.93 181.112 152.624 181.547 152.394L186.428 149.941C186.864 149.711 187.561 149.711 187.997 149.865L192.965 152.394C193.4 152.624 193.313 152.93 192.877 153.16L187.997 155.613C187.561 155.843 186.864 155.843 186.428 155.69Z"
                                    fill="#553DB4" />
                                <path
                                    d="M194.356 160.465L189.885 157.96C189.493 157.732 189.571 157.428 189.963 157.2L194.356 154.771C194.748 154.543 195.375 154.543 195.768 154.695L200.239 157.2C200.631 157.428 200.552 157.732 200.16 157.96L195.768 160.389C195.297 160.693 194.67 160.693 194.356 160.465Z"
                                    fill="#553DB4" />
                                <path
                                    d="M200.922 164.112L195.954 161.597C195.519 161.368 195.606 161.063 196.042 160.834L200.922 158.394C201.358 158.166 202.055 158.166 202.491 158.318L207.459 160.834C207.895 161.063 207.807 161.368 207.372 161.597L202.491 164.036C202.055 164.265 201.358 164.341 200.922 164.112Z"
                                    fill="#553DB4" />
                                <path
                                    d="M208.168 168.887L203.201 165.881C202.765 165.607 202.852 165.243 203.288 164.97L208.168 162.054C208.604 161.781 209.301 161.781 209.737 161.963L214.705 164.97C215.141 165.243 215.053 165.607 214.618 165.881L209.737 168.796C209.214 169.161 208.517 169.161 208.168 168.887Z"
                                    fill="#553DB4" />
                                <path
                                    d="M215.414 172.568L210.447 170.052C210.011 169.823 210.098 169.518 210.534 169.289L215.414 166.849C215.85 166.621 216.547 166.621 216.983 166.773L221.951 169.289C222.387 169.518 222.3 169.823 221.864 170.052L216.983 172.491C216.547 172.72 215.85 172.796 215.414 172.568Z"
                                    fill="#553DB4" />
                                <path
                                    d="M222.661 177.342L217.693 174.335C217.257 174.062 217.344 173.697 217.78 173.424L222.661 170.508C223.096 170.235 223.794 170.235 224.229 170.417L229.197 173.424C229.633 173.697 229.546 174.062 229.11 174.335L224.229 177.25C223.706 177.615 223.009 177.615 222.661 177.342Z"
                                    fill="#553DB4" />
                                <path
                                    d="M153.819 129.121L148.851 126.592C148.415 126.362 148.502 126.055 148.938 125.826L153.819 123.373C154.254 123.143 154.952 123.143 155.387 123.296L160.355 125.826C160.791 126.055 160.704 126.362 160.268 126.592L155.387 129.045C154.865 129.275 154.167 129.275 153.819 129.121Z"
                                    fill="#553DB4" />
                                <path
                                    d="M147.256 125.408L142.785 122.401C142.393 122.128 142.471 121.764 142.864 121.49L147.256 118.575C147.648 118.301 148.276 118.301 148.668 118.483L153.139 121.49C153.531 121.764 153.453 122.128 153.061 122.401L148.668 125.317C148.197 125.681 147.57 125.681 147.256 125.408Z"
                                    fill="#553DB4" />
                                <path
                                    d="M140.01 120.635L135.539 118.119C135.147 117.89 135.225 117.585 135.618 117.357L140.01 114.917C140.402 114.688 141.03 114.688 141.422 114.841L145.893 117.357C146.285 117.585 146.207 117.89 145.815 118.119L141.422 120.559C140.951 120.787 140.324 120.864 140.01 120.635Z"
                                    fill="#553DB4" />
                                <path
                                    d="M132.764 117.021L128.293 113.986C127.901 113.711 127.979 113.343 128.371 113.067L132.764 110.123C133.156 109.848 133.784 109.848 134.176 110.032L138.647 113.067C139.039 113.343 138.961 113.711 138.568 113.986L134.176 116.93C133.705 117.205 133.078 117.205 132.764 117.021Z"
                                    fill="#553DB4" />
                                <path
                                    d="M140.299 112.168L135.555 109.429C135.139 109.18 135.222 108.848 135.638 108.599L137.219 107.686C137.636 107.437 138.302 107.437 138.718 107.603L143.462 110.342C143.878 110.591 143.795 110.923 143.379 111.172L141.797 112.085C141.381 112.334 140.715 112.417 140.299 112.168Z"
                                    fill="#553DB4" />
                                <path
                                    d="M149.244 118.241L145.178 115.482C144.821 115.231 144.893 114.897 145.249 114.646L146.605 113.726C146.961 113.476 147.532 113.476 147.889 113.643L151.955 116.402C152.312 116.652 152.241 116.987 151.884 117.238L150.528 118.157C150.1 118.408 149.53 118.408 149.244 118.241Z"
                                    fill="#553DB4" />
                                <path
                                    d="M155.998 121.864L151.254 119.105C150.838 118.854 150.921 118.52 151.337 118.269L152.919 117.35C153.335 117.099 154.001 117.099 154.417 117.266L159.161 120.025C159.577 120.275 159.494 120.61 159.078 120.861L157.496 121.78C156.997 122.031 156.331 122.031 155.998 121.864Z"
                                    fill="#553DB4" />
                                <path
                                    d="M163.244 126.661L158.5 123.922C158.084 123.673 158.167 123.341 158.583 123.092L160.165 122.179C160.581 121.93 161.247 121.93 161.663 122.096L166.407 124.835C166.823 125.084 166.74 125.416 166.324 125.665L164.742 126.578C164.326 126.827 163.661 126.91 163.244 126.661Z"
                                    fill="#553DB4" />
                                <path
                                    d="M170.492 130.285L165.748 127.546C165.332 127.297 165.415 126.965 165.831 126.716L167.413 125.803C167.829 125.554 168.495 125.554 168.911 125.72L173.655 128.459C174.071 128.708 173.988 129.04 173.572 129.289L171.991 130.202C171.574 130.451 170.908 130.534 170.492 130.285Z"
                                    fill="#553DB4" />
                                <path
                                    d="M210.348 153.264L205.604 150.506C205.188 150.255 205.271 149.92 205.687 149.67L207.268 148.75C207.684 148.499 208.35 148.499 208.766 148.666L213.511 151.425C213.927 151.676 213.843 152.01 213.427 152.261L211.846 153.181C211.43 153.431 210.764 153.431 210.348 153.264Z"
                                    fill="#553DB4" />
                                <path
                                    d="M217.594 158.096L212.85 155.338C212.434 155.087 212.517 154.752 212.933 154.502L214.514 153.582C214.931 153.331 215.596 153.331 216.013 153.498L220.757 156.257C221.173 156.508 221.09 156.842 220.673 157.093L219.092 158.013C218.593 158.263 217.927 158.263 217.594 158.096Z"
                                    fill="#553DB4" />
                                <path
                                    d="M224.838 161.72L220.094 158.962C219.678 158.711 219.761 158.376 220.177 158.126L221.758 157.206C222.175 156.955 222.841 156.955 223.257 157.122L228.001 159.881C228.417 160.132 228.334 160.466 227.918 160.717L226.336 161.637C225.837 161.887 225.171 161.887 224.838 161.72Z"
                                    fill="#553DB4" />
                                <path
                                    d="M232.086 165.341L227.342 162.583C226.926 162.332 227.009 161.998 227.425 161.747L229.007 160.827C229.423 160.576 230.089 160.576 230.505 160.743L235.249 163.502C235.665 163.753 235.582 164.087 235.166 164.338L233.584 165.258C233.168 165.509 232.502 165.509 232.086 165.341Z"
                                    fill="#553DB4" />
                                <path
                                    d="M161.067 133.864L156.099 130.857C155.663 130.584 155.75 130.22 156.186 129.946L161.067 127.031C161.503 126.757 162.2 126.757 162.636 126.939L167.603 129.946C168.039 130.22 167.952 130.584 167.516 130.857L162.636 133.773C162.2 134.137 161.503 134.137 161.067 133.864Z"
                                    fill="#553DB4" />
                                <path
                                    d="M168.313 137.574L163.345 135.045C162.909 134.815 162.997 134.509 163.432 134.279L168.313 131.826C168.749 131.596 169.446 131.596 169.882 131.749L174.849 134.279C175.285 134.509 175.198 134.815 174.762 135.045L169.882 137.498C169.359 137.728 168.661 137.728 168.313 137.574Z"
                                    fill="#553DB4" />
                                <path
                                    d="M175.559 142.318L170.591 139.311C170.155 139.038 170.243 138.674 170.678 138.4L175.559 135.485C175.995 135.211 176.692 135.211 177.128 135.394L182.095 138.4C182.531 138.674 182.444 139.038 182.008 139.311L177.128 142.227C176.692 142.591 175.995 142.591 175.559 142.318Z"
                                    fill="#553DB4" />
                                <path
                                    d="M182.805 146.028L177.837 143.499C177.401 143.269 177.489 142.963 177.924 142.733L182.805 140.28C183.241 140.05 183.938 140.05 184.374 140.204L189.342 142.733C189.777 142.963 189.69 143.269 189.254 143.499L184.374 145.952C183.851 146.182 183.241 146.182 182.805 146.028Z"
                                    fill="#553DB4" />
                                <path
                                    d="M190.051 149.596L185.083 147.09C184.648 146.863 184.735 146.559 185.171 146.331L190.051 143.901C190.487 143.674 191.184 143.674 191.62 143.826L196.588 146.331C197.023 146.559 196.936 146.863 196.501 147.09L191.62 149.52C191.097 149.824 190.4 149.824 190.051 149.596Z"
                                    fill="#553DB4" />
                                <path
                                    d="M197.297 154.424L192.329 151.405C191.894 151.131 191.981 150.765 192.417 150.49L197.297 147.563C197.733 147.288 198.43 147.288 198.866 147.471L203.834 150.49C204.27 150.765 204.182 151.131 203.747 151.405L198.866 154.333C198.43 154.607 197.733 154.699 197.297 154.424Z"
                                    fill="#553DB4" />
                                <path
                                    d="M204.545 158.05L199.578 155.545C199.142 155.317 199.229 155.013 199.665 154.785L204.545 152.356C204.981 152.128 205.678 152.128 206.114 152.28L211.082 154.785C211.518 155.013 211.43 155.317 210.995 155.545L206.114 157.974C205.591 158.278 204.894 158.278 204.545 158.05Z"
                                    fill="#553DB4" />
                                <path
                                    d="M211.791 162.878L206.824 159.858C206.388 159.584 206.475 159.218 206.911 158.944L211.791 156.016C212.227 155.741 212.924 155.741 213.36 155.924L218.328 158.944C218.764 159.218 218.677 159.584 218.241 159.858L213.36 162.786C212.924 163.061 212.227 163.152 211.791 162.878Z"
                                    fill="#553DB4" />
                                <path
                                    d="M219.037 166.503L214.07 163.998C213.634 163.77 213.721 163.466 214.157 163.238L219.037 160.809C219.473 160.581 220.17 160.581 220.606 160.733L225.574 163.238C226.01 163.466 225.923 163.77 225.487 163.998L220.606 166.427C220.083 166.731 219.386 166.731 219.037 166.503Z"
                                    fill="#553DB4" />
                                <path
                                    d="M120.36 133.877L106.566 125.742C106.153 125.488 106.236 125.149 106.649 124.895L111.274 122.183C111.687 121.929 112.348 121.929 112.761 122.099L126.555 130.233C126.968 130.487 126.885 130.826 126.472 131.08L121.847 133.792C121.434 134.131 120.773 134.131 120.36 133.877Z"
                                    fill="#553DB4" />
                                <path
                                    d="M208.526 184.62L194.732 177.299C194.319 177.07 194.402 176.765 194.815 176.536L199.44 174.096C199.853 173.867 200.514 173.867 200.927 174.019L214.721 181.34C215.134 181.569 215.051 181.874 214.638 182.103L210.013 184.543C209.6 184.848 208.939 184.848 208.526 184.62Z"
                                    fill="#553DB4" />
                                <path
                                    d="M180.005 177.419L175.402 174.631C174.998 174.378 175.079 174.04 175.483 173.786L180.893 170.492C181.296 170.239 181.942 170.239 182.346 170.408L186.949 173.195C187.352 173.449 187.272 173.787 186.868 174.04L181.458 177.334C181.054 177.588 180.408 177.588 180.005 177.419Z"
                                    fill="#553DB4" />
                                <path
                                    d="M127.644 147.23L119.861 143.035C119.434 142.793 119.519 142.47 119.947 142.228L125.677 139.082C126.105 138.84 126.789 138.84 127.217 139.001L134.999 143.196C135.427 143.438 135.342 143.761 134.914 144.003L129.184 147.15C128.67 147.392 127.986 147.392 127.644 147.23Z"
                                    fill="#553DB4" />
                                <path
                                    d="M172.918 173.769L165.734 169.59C165.339 169.349 165.418 169.027 165.813 168.786L171.103 165.652C171.497 165.411 172.129 165.411 172.524 165.572L179.708 169.751C180.103 169.992 180.024 170.313 179.629 170.554L174.339 173.688C173.866 173.929 173.234 174.01 172.918 173.769Z"
                                    fill="#553DB4" />
                                <path
                                    d="M137.893 153.269L130.709 149.074C130.314 148.832 130.393 148.509 130.788 148.267L136.077 145.121C136.472 144.879 137.103 144.879 137.498 145.04L144.682 149.235C145.077 149.477 144.998 149.8 144.603 150.042L139.314 153.189C138.84 153.431 138.209 153.431 137.893 153.269Z"
                                    fill="#553DB4" />
                                <path
                                    d="M137.535 144.928L169.995 163.663C170.407 163.91 170.407 164.238 169.995 164.485L164.393 167.69C163.981 167.936 163.322 167.936 162.91 167.69L130.449 148.954L137.535 144.928Z"
                                    fill="#553DB4" />
                                <path
                                    d="M198.57 185.087L203.8 187.929C204.114 188.088 204.036 188.405 203.722 188.564L201.605 189.834C201.292 189.993 200.743 190.072 200.43 189.913L195.2 187.071C194.886 186.912 194.964 186.595 195.278 186.436L197.394 185.166C197.786 185.007 198.257 184.928 198.57 185.087Z"
                                    fill="#553DB4" />
                                <path
                                    d="M191.57 181.087L196.8 183.929C197.114 184.088 197.036 184.405 196.722 184.564L194.605 185.834C194.292 185.993 193.743 186.072 193.43 185.913L188.2 183.071C187.886 182.912 187.964 182.595 188.278 182.436L190.394 181.166C190.786 181.007 191.257 180.928 191.57 181.087Z"
                                    fill="#553DB4" />
                                <path
                                    d="M195.641 178.673L200.871 181.516C201.184 181.675 201.106 181.992 200.792 182.151L198.676 183.421C198.362 183.58 197.814 183.659 197.5 183.5L192.27 180.658C191.956 180.499 192.035 180.182 192.348 180.023L194.465 178.753C194.857 178.594 195.327 178.515 195.641 178.673Z"
                                    fill="#553DB4" />
                                <path
                                    d="M184.57 177.087L189.8 179.929C190.114 180.088 190.036 180.405 189.722 180.564L187.605 181.834C187.292 181.993 186.743 182.072 186.43 181.913L181.2 179.071C180.886 178.912 180.964 178.595 181.278 178.436L183.394 177.166C183.786 177.007 184.257 176.928 184.57 177.087Z"
                                    fill="#553DB4" />
                                <path
                                    d="M186.302 80.918C186.136 80.918 185.888 80.8362 185.722 80.7544L151.264 61.1191C150.436 60.6282 149.773 59.3192 149.773 58.2556V31.5024C149.773 30.6842 150.188 30.1934 150.685 30.1934C150.85 30.1934 151.099 30.2751 151.264 30.3569L185.722 49.9923C186.55 50.4832 187.213 51.7922 187.213 52.8558V79.609C187.213 80.4271 186.882 80.918 186.302 80.918Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M162.581 62.5862L162.328 62.4289C161.401 61.9568 160.643 60.6192 160.643 59.5176V47.2426C160.643 46.141 161.401 45.5902 162.328 46.0623L162.581 46.2198C163.507 46.6919 164.266 48.0295 164.266 49.1311V61.4059C164.266 62.5862 163.507 63.137 162.581 62.5862Z"
                                    fill="#553DB4" />
                                <path
                                    d="M168.62 67.4417L168.367 67.2638C167.44 66.73 166.682 65.2174 166.682 63.9717V55.8752C166.682 54.6295 167.44 54.0067 168.367 54.5406L168.62 54.7186C169.546 55.2524 170.305 56.7649 170.305 58.0106V66.1071C170.221 67.3527 169.462 67.9756 168.62 67.4417Z"
                                    fill="#553DB4" />
                                <path
                                    d="M175.866 71.0757L175.613 70.9089C174.686 70.4088 173.928 68.9919 173.928 67.8251V49.7386C173.928 48.5717 174.686 47.9883 175.613 48.4884L175.866 48.6551C176.793 49.1551 177.551 50.572 177.551 51.7389V69.8254C177.551 70.9923 176.793 71.5757 175.866 71.0757Z"
                                    fill="#553DB4" />
                                <path
                                    d="M218.416 73.4876L197.074 61.7714C196.247 61.2996 195.668 60.1988 195.668 59.1766C195.668 58.1543 196.33 57.6825 197.074 58.1543L218.416 69.8705C219.244 70.3423 219.823 71.4431 219.823 72.4653C219.823 73.4876 219.244 73.9594 218.416 73.4876Z"
                                    fill="#553DB4" />
                                <path
                                    d="M205.071 73.4815L197.134 69.1594C196.272 68.6701 195.668 67.5285 195.668 66.4683C195.668 65.4082 196.358 64.9189 197.134 65.4082L205.071 69.7303C205.934 70.2196 206.538 71.3612 206.538 72.4213C206.538 73.4814 205.934 73.9708 205.071 73.4815Z"
                                    fill="#553DB4" />
                                <path
                                    d="M217.309 80.7276L210.255 76.4055C209.488 75.9162 208.951 74.7746 208.951 73.7144C208.951 72.6543 209.565 72.165 210.255 72.6543L217.309 76.9764C218.076 77.4657 218.613 78.6073 218.613 79.6674C218.613 80.7275 218 81.2169 217.309 80.7276Z"
                                    fill="#553DB4" />
                                <path
                                    d="M234.038 90.4271L222.514 83.8762C221.64 83.359 221.029 82.1524 221.029 81.0318C221.029 79.9113 221.728 79.394 222.514 79.9112L234.038 86.4621C234.911 86.9793 235.522 88.1861 235.522 89.3066C235.522 90.341 234.824 90.8581 234.038 90.4271Z"
                                    fill="#553DB4" />
                                <path
                                    d="M224.417 91.6315L197.105 76.4175C196.26 75.9268 195.668 74.7816 195.668 73.7182C195.668 72.6549 196.344 72.1641 197.105 72.6548L224.417 87.8689C225.262 88.3597 225.854 89.5048 225.854 90.5681C225.939 91.6315 225.262 92.0405 224.417 91.6315Z"
                                    fill="#553DB4" />
                                <path
                                    d="M235.312 106.082L197.087 83.8043C196.252 83.296 195.668 82.1101 195.668 81.0089C195.668 79.9077 196.336 79.3995 197.087 79.9078L235.312 102.186C236.147 102.694 236.731 103.88 236.731 104.981C236.731 106.082 236.147 106.59 235.312 106.082Z"
                                    fill="#553DB4" />
                                <path
                                    d="M238.923 85.603L224.875 77.6673C224.034 77.1714 223.445 76.0141 223.445 74.9395C223.445 73.8649 224.118 73.3689 224.875 73.8649L238.923 81.8005C239.765 82.2964 240.354 83.4537 240.354 84.5284C240.354 85.5203 239.681 86.0163 238.923 85.603Z"
                                    fill="#553DB4" />
                                <path d="M157.02 85.7482L149.773 82.125V89.3713L157.02 92.9946V85.7482Z" fill="#553DB4" />
                                <path d="M167.889 86.0129L160.643 82.125V96.353L167.889 100.241V86.0129Z" fill="#E2DCFF" />
                                <path d="M178.758 95.8186L171.512 91.7881V102.25L178.758 106.281V95.8186Z" fill="#E2DCFF" />
                                <path d="M188.421 99.2689L182.383 95.4111V108.462L188.421 112.319V99.2689Z"
                                    fill="#E2DCFF" />
                                <path d="M199.291 105.504L193.252 101.449V114.303L199.291 118.357V105.504Z"
                                    fill="#553DB4" />
                                <path d="M210.16 108.971L202.914 105.073V120.499L210.16 124.397V108.971Z" fill="#553DB4" />
                                <path d="M221.03 113.718L213.783 109.903V126.621L221.03 130.435V113.718Z" fill="#E2DCFF" />
                                <path d="M230.691 115.045L224.652 111.111V132.54L230.691 136.474V115.045Z" fill="#553DB4" />
                                <path d="M188.422 172.102L175.096 164.252L161.852 172.102L175.096 179.952L188.422 172.102Z"
                                    fill="#553DB4" fill-opacity="0.46" />
                                <path d="M146.149 173.352L132.302 165.46L118.371 173.352L132.302 181.16L146.149 173.352Z"
                                    fill="#553DB4" fill-opacity="0.46" />
                                <path d="M174.778 189.892L160.931 182L147 189.892L160.931 197.7L174.778 189.892Z"
                                    fill="#553DB4" fill-opacity="0.46" />
                                <g id="chuvak_shadow">
                                    <path
                                        d="M117.166 157.567L103.881 149.758L90.5957 157.567L103.881 165.458L117.166 157.567Z"
                                        fill="#553DB4" fill-opacity="0.46" />
                                </g>
                                <path d="M246.392 216.183V184.598L236.73 178.743V210.245L246.392 216.183Z" fill="#C13F27" />
                                <path d="M246.393 184.598L257.262 178.743V210.245L246.393 216.183V184.598Z"
                                    fill="#EF8F00" />
                                <path d="M246.392 216.183V184.598L236.73 178.743V210.245L246.392 216.183Z" fill="#FFAD00" />
                                <path d="M224.652 228.261V211.071L214.99 205.314V222.505L224.652 228.261Z" fill="#FFAD00" />
                                <path d="M224.652 211.071L235.522 205.314V222.505L224.652 228.261V211.071Z"
                                    fill="#EF8F00" />
                                <path d="M214.99 205.314L225.256 199.275L235.522 205.314L225.256 211.353L214.99 205.314Z"
                                    fill="#FFD988" />
                                <path d="M268.131 202.898V160.415L258.469 154.589V197.072L268.131 202.898Z"
                                    fill="#FFAD00" />
                                <path d="M258.469 154.588L268.734 148.55L279 154.588L268.734 160.627L258.469 154.588Z"
                                    fill="#FFD988" />
                                <path d="M236.73 178.786L246.996 172.705L257.262 178.786L246.996 184.782L236.73 178.786Z"
                                    fill="#FFD988" />
                                <path d="M160.643 190.822V167.271L175.135 159.421V182.972L160.643 190.822Z"
                                    fill="#553DB4" />
                                <path d="M160.642 167.271L147.357 159.421V182.972L160.642 190.822V167.271Z"
                                    fill="#A99DD8" />
                                <path d="M175.135 159.65L161.204 152L147.357 159.65L161.204 168L175.135 159.65Z"
                                    fill="#E2DCFF" />
                                <path d="M132.864 132.223L118.371 124.396V166.085L132.864 173.912V132.223Z"
                                    fill="#A99DD8" />
                                <path d="M132.865 173.912V132.223L146.15 124.396V166.085L132.865 173.912Z" fill="#553DB4" />
                                <path d="M146.151 124.65L132.304 117.5L118.373 124.65L132.304 132.5L146.151 124.65Z"
                                    fill="#E2DCFF" />
                                <g id="chuvak">
                                    <path d="M103.881 157.006V102.37L117.166 94.9014V149.616L103.881 157.006Z"
                                        fill="#EF8F00" />
                                    <path d="M103.881 102.37L90.5957 94.9014V149.616L103.881 157.006V102.37Z"
                                        fill="#FFAD00" />
                                    <path d="M117.166 95.4755L103.881 88L90.5957 95.4755L103.881 102.951L117.166 95.4755Z"
                                        fill="#FFD988" />
                                    <path
                                        d="M95.9403 124.751C95.9403 124.751 93.4753 123.663 91.9233 125.295C90.3713 126.849 88.2715 130.58 88.1802 133.145C88.0889 135.633 90.5538 135.477 91.9233 134.933C93.384 134.311 97.5836 129.181 97.7662 128.171C98.04 127.16 97.4922 125.761 97.0357 125.528C96.7619 125.217 95.9403 124.751 95.9403 124.751Z"
                                        fill="#A99DD8" />
                                    <path
                                        d="M96.5426 123.762L96.6337 126.31C96.6337 126.855 96.3602 127.401 95.9046 127.674C95.449 127.947 94.8111 128.129 93.991 127.947C92.1685 127.674 91.9863 127.128 91.804 124.581C91.7129 122.034 96.5426 123.762 96.5426 123.762Z"
                                        fill="#E2DCFF" />
                                    <path
                                        d="M87.7238 119.959C87.7238 119.959 85.567 118.75 84.209 120.564C82.851 122.291 81.0137 126.437 80.9338 129.287C80.8539 132.05 83.0107 131.878 84.209 131.273C85.4871 130.582 89.1617 124.882 89.3215 123.759C89.5612 122.637 89.0818 121.082 88.6824 120.823C88.4428 120.477 87.7238 119.959 87.7238 119.959Z"
                                        fill="#A99DD8" />
                                    <path
                                        d="M88.0875 118.929L88.1786 121.477C88.1786 122.022 87.9053 122.568 87.4496 122.841C86.994 123.114 86.356 123.296 85.5359 123.114C83.7133 122.841 83.5312 122.295 83.3489 119.748C83.2578 117.201 88.0875 118.929 88.0875 118.929Z"
                                        fill="#E2DCFF" />
                                    <path
                                        d="M107.455 76.8389C106.793 66.161 95.7842 78.3876 95.7842 78.3876L88.2519 85.7235L81.4645 93.0595C80.1401 94.5267 79.4779 96.4014 79.809 98.3577L82.5405 118.98C82.706 120.04 83.5337 120.773 84.6098 120.773C84.9409 120.773 85.272 120.773 85.6031 120.692C88.0863 120.284 87.838 118.002 87.838 118.002L87.3414 98.6837L101.661 90.2066C101.578 90.2066 108.117 87.5167 107.455 76.8389Z"
                                        fill="#553DB4" />
                                    <path
                                        d="M115.908 80.5713C115.246 69.6265 104.237 82.1587 104.237 82.1587L96.705 89.6781L89.9176 97.1974C88.5932 98.7013 87.931 100.623 88.2621 102.628L90.9937 123.766C91.1592 124.852 91.9869 125.604 93.063 125.604C93.3941 125.604 93.7251 125.604 94.0562 125.52C96.5394 125.103 96.2912 122.763 96.2912 122.763L95.7946 102.962L110.114 94.2732C110.032 94.2732 116.571 91.4326 115.908 80.5713Z"
                                        fill="#553DB4" />
                                    <path
                                        d="M109.03 47.0767C117.328 50.4791 117.164 55.3397 117.164 55.3397C117.164 55.3397 115.521 73.081 116.096 84.9896C116.343 89.9312 89.394 90.3363 93.4198 77.1316C98.1851 61.5776 96.3776 48.9398 98.596 46.9956C100.814 44.9703 106.894 46.1856 109.03 47.0767Z"
                                        fill="#A99DD8" />
                                    <path
                                        d="M112.266 51.1208C111.855 52.7375 109.631 53.5075 107.326 52.9685C105.02 52.4296 103.538 50.6588 103.949 49.1191C104.361 47.5023 106.585 46.7324 108.89 47.2713C111.196 47.8102 112.678 49.504 112.266 51.1208Z"
                                        fill="#FBC1B1" />
                                    <path
                                        d="M107.503 46.6608C106.071 46.6608 104.941 47.3988 104.639 48.5468C104.263 50.0229 105.544 51.663 107.427 52.155C109.311 52.647 111.195 51.909 111.497 50.4329C111.647 49.7769 111.497 48.9569 110.969 48.3009C110.442 47.5629 109.613 47.0708 108.709 46.8248C108.256 46.7428 107.88 46.6608 107.503 46.6608ZM108.633 53.139C108.181 53.139 107.729 53.057 107.277 52.975C105.016 52.319 103.509 50.2689 103.961 48.3829C104.413 46.4968 106.674 45.4307 108.935 46.0868C109.99 46.4148 110.969 46.9888 111.572 47.8909C112.25 48.7929 112.476 49.7769 112.25 50.6789C111.873 52.1549 110.366 53.139 108.633 53.139Z"
                                        fill="white" />
                                    <path
                                        d="M106.366 46.4492C106.366 46.4492 106.435 46.7933 106.296 49.5463C106.227 50.4066 108.731 51.1809 109.358 50.4066C109.984 49.6323 109.706 48.7719 109.914 45.8469C110.053 42.9218 106.366 46.4492 106.366 46.4492Z"
                                        fill="#FBC1B1" />
                                    <path
                                        d="M109.918 45.8926C109.918 45.8926 109.291 48.5276 106.295 49.5158L106.365 47.1277L109.918 45.8926Z"
                                        fill="#EA9C92" />
                                    <path
                                        d="M112.133 38.8003C112.983 42.3727 111.05 45.9452 107.802 46.8809C104.555 47.8165 101.307 45.6901 100.456 42.1176C99.6058 38.5451 101.539 34.9726 104.787 34.037C107.957 33.1013 111.282 35.2278 112.133 38.8003Z"
                                        fill="#FBC1B1" />
                                    <path
                                        d="M107.502 40.4591C107.502 40.4591 104.28 42.1753 102.032 42.2707C99.7845 42.2707 100.309 39.8869 100.309 39.8869L106.903 38.6475L107.502 40.4591Z"
                                        fill="#EA9C92" />
                                    <path
                                        d="M100.657 41.299C100.657 41.299 103.697 39.9468 106.409 40.5384C107.066 40.7074 107.642 41.0454 108.052 41.5525C108.217 41.7215 108.381 41.8905 108.545 41.8905C109.038 41.975 109.778 39.9468 110.764 39.9468C111.668 39.8623 112.325 40.5384 111.586 41.806C110.846 43.0737 111.093 44.4258 111.421 44.6794C111.75 44.8484 115.448 41.975 112.243 35.2988C108.956 28.6225 100.657 32.2564 99.3425 33.6086C99.3425 33.6086 97.9456 34.7918 96.8774 33.7777C96.713 33.6931 95.4804 43.2427 100.657 41.299Z"
                                        fill="#FFAD00" />
                                    <path
                                        d="M112.928 68.0905V73.0755C112.928 73.6669 112.692 74.1738 112.297 74.5963L102.672 83.9748L103.776 85.7492L116.479 75.8636C117.11 75.4412 117.426 74.6808 117.505 73.9204L118.372 65.2178L112.928 68.0905Z"
                                        fill="#FBC1B1" />
                                    <path
                                        d="M116.795 51.9316C116.795 51.9316 119.692 56.6655 120.788 69.0075C120.788 69.0075 115.855 70.6983 113.036 69.7684C110.217 68.754 116.795 51.9316 116.795 51.9316Z"
                                        fill="#A99DD8" />
                                    <path
                                        d="M112.333 57.9707C112.333 57.9707 111.206 64.1248 111.125 67.082C111.125 67.8812 111.608 68.6005 112.333 68.8403V57.9707Z"
                                        fill="#553DB4" />
                                    <path
                                        d="M109.918 89.4525L107.666 89.1309L93.1531 81.0899C92.6527 80.8487 91.902 80.8487 91.3182 81.1703L81.0591 86.8794L79.7246 86.7186V88.0051C79.7246 88.1659 79.808 88.3267 80.0583 88.4875L96.4894 97.6542C96.9898 97.8955 97.7404 97.8955 98.3243 97.5738L109.501 91.3824C109.834 91.2215 109.918 90.9803 109.918 90.8194V89.4525Z"
                                        fill="#A99DD8" />
                                    <path
                                        d="M99.0483 85.1207L98.0445 84.541H86.6678L81.063 87.7709L79.7246 87.6052V88.9302C79.7246 89.0958 79.8083 89.2615 80.0592 89.4272L96.5387 98.8681C96.7897 99.0337 97.1243 99.0338 97.4589 99.0338L99.0483 85.1207Z"
                                        fill="#553DB4" />
                                    <path
                                        d="M80.9323 87.0567L79.7246 86.9561V88.5663C79.7246 88.7676 79.8972 88.9689 80.4148 89.1702L80.9323 89.3715V87.0567Z"
                                        fill="#A99DD8" />
                                    <path
                                        d="M96.6328 98.8681C96.9683 99.1066 97.5051 99.1066 97.8405 98.749V95.4111H96.6328V98.8681Z"
                                        fill="white" />
                                    <path
                                        d="M96.4437 96.4716L80.0524 87.2467C79.5532 87.0039 79.6364 86.5185 80.2188 86.1948L91.3682 79.9639C91.8675 79.6402 92.6995 79.6403 93.1987 79.883L109.59 89.1079C110.089 89.3507 110.006 89.8361 109.424 90.1598L98.2742 96.3907C97.7749 96.6335 96.9429 96.7144 96.4437 96.4716Z"
                                        fill="white" />
                                    <path
                                        d="M96.0436 95.2364L82.4138 87.5933C81.9983 87.3493 82.0814 87.0241 82.4969 86.7801L88.4807 83.5277C88.8963 83.2838 89.5612 83.2837 89.9767 83.4463L103.607 91.0896C104.022 91.3335 103.939 91.6588 103.523 91.9027L97.5396 95.1551C97.1241 95.4804 96.4592 95.4804 96.0436 95.2364Z"
                                        fill="#E2DCFF" />
                                    <path
                                        d="M92.0621 80.2188L91.8943 81.2628C91.7264 82.1576 91.8103 82.9779 91.9782 83.8728L92.1462 84.7677C92.2302 85.2897 92.7338 85.6625 93.2376 85.7371C93.6574 85.8117 94.0772 85.5134 94.1611 85.1406L94.4131 82.978C94.4131 82.978 95.0008 83.7237 95.3366 83.7237C95.6724 83.7237 95.0008 81.5611 94.3291 80.2934C93.6574 79.0257 92.0621 80.2188 92.0621 80.2188Z"
                                        fill="#FBC1B1" />
                                    <path
                                        d="M97.8421 62.3743L95.9398 65.7296C95.4869 66.5285 95.2153 67.4072 95.1247 68.286L94.3094 79.7101L91.7732 79.6302L90.5957 66.8481C90.5957 66.4486 90.5957 66.0492 90.6863 65.6498L91.9544 59.1787L97.8421 62.3743Z"
                                        fill="#FBC1B1" />
                                    <path
                                        d="M95.4962 97.8262L94.1964 95.9771L80.1423 87.572C79.6549 87.3199 79.33 86.5634 79.33 85.975V74.0399L78.5176 72.9472L79.2487 72.5269C79.4112 72.4429 79.5736 72.4429 79.8174 72.5269L95.8212 82.1087C96.3086 82.3609 96.6335 83.1174 96.6335 83.7057V96.7336C96.6335 97.0698 96.5523 97.3219 96.3086 97.4059L95.4962 97.8262Z"
                                        fill="#553DB4" />
                                    <path
                                        d="M85.9286 90.5798L84.9404 90.0053L79.341 80.3216V74.0025L78.5176 72.9357L79.2587 72.5254C79.4234 72.4434 79.588 72.4434 79.8351 72.5254L96.057 81.8809C96.3041 82.045 96.5512 82.2911 96.6335 82.6194L85.9286 90.5798Z"
                                        fill="#553DB4" />
                                    <path
                                        d="M78.7718 73.6716L78.5176 73.0549L79.0897 72.541C79.2168 72.4382 79.3439 72.4382 79.5346 72.541L79.7253 72.7465L78.7718 73.6716Z"
                                        fill="#A99DD8" />
                                    <path
                                        d="M95.9441 82.125C96.2892 82.4701 96.6342 82.9876 96.6342 83.5915L94.9951 84.5405L94.2188 83.1602L95.9441 82.125Z"
                                        fill="#553DB4" fill-opacity="0.46" />
                                    <path
                                        d="M95.7987 82.9959L79.3524 73.7643C78.8515 73.5213 78.5176 73.7643 78.5176 74.4121V86.9639C78.5176 87.5308 78.8515 88.2595 79.3524 88.5024L95.7987 97.7341C96.2996 97.977 96.6335 97.7341 96.6335 97.0863V84.5345C96.6335 83.8867 96.2996 83.2388 95.7987 82.9959Z"
                                        fill="#A99DD8" />
                                    <path
                                        d="M103.88 85.0473L103.603 85.5926C103.232 86.216 102.769 86.6835 102.121 86.9952L100.268 87.9302C99.7128 88.2418 98.9718 88.2418 98.4162 87.9302H98.3236C97.7679 87.6185 97.6752 86.9173 98.1383 86.5277L99.9906 84.8914C99.9906 84.8914 98.9718 85.5926 98.6013 85.281C98.4161 85.0472 99.6202 84.1122 100.546 84.0343C101.472 84.0343 102.399 83.333 102.399 83.333L103.88 85.0473Z"
                                        fill="#FBC1B1" />
                                    <path
                                        d="M98.9657 45.8926C98.9657 45.8926 92.9871 52.5301 90.5957 58.6759C90.5957 58.6759 92.6683 62.4454 97.6106 63.9204C102.633 65.4773 98.9657 45.8926 98.9657 45.8926Z"
                                        fill="#A99DD8" />
                                    <path
                                        d="M96.8417 64.0105L97.8412 54.3486L95.4258 63.4325C95.9255 63.5976 96.3419 63.7627 96.8417 64.0105Z"
                                        fill="#553DB4" />
                                    <path
                                        d="M103.88 45.0522L102.672 44.6865C102.672 44.6865 103.276 45.6007 104.121 45.8445C104.967 46.0883 105.087 45.3569 105.087 45.3569L103.88 45.0522Z"
                                        fill="white" />
                                </g>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 ">
                <div class="anim-block">
                    <h2>Закупки <br> для бизнеса</h2>
                    <p>
                        Узнайте по каким ценам покупает товары государство, недропользователи.
                    </p>
                    <a href="#" class="anim__btn">Перейти</a>
                    <div class="anim__image">
                        <svg width="212" height="228" viewBox="0 0 212 228" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M192.971 61.4418L87.7613 0.716999C86.4389 -0.0265704 85.1992 -0.191788 84.1248 0.221306L83.9594 0.303905H83.8768L80.4883 1.95628L84.2074 3.77386V3.93915V90.3584C84.2074 94.6546 86.8521 99.6943 90.1579 101.595L192.558 160.667L193.136 165.459L196.855 163.641C198.095 162.898 198.839 161.245 198.839 158.932V72.5128C199.004 68.3818 196.277 63.3421 192.971 61.4418Z"
                                fill="#553DB4" />
                            <path
                                d="M192.322 61.6478L87.7193 0.719403C86.4046 -0.0266594 85.172 -0.192431 84.1038 0.222048L83.9394 0.304924H83.8572L80.4883 1.96284L84.1859 3.78651V3.95236V61.0675L131.762 99.0338L196.43 66.2071C195.444 64.3005 193.965 62.5597 192.322 61.6478Z"
                                fill="#553DB4" />
                            <path
                                d="M194.825 62.8027L184.354 67.3244L192.228 73.6723L198.846 70.8027C198.26 67.7592 196.752 64.8027 194.825 62.8027Z"
                                fill="#274F89" />
                            <path
                                d="M189.271 164.742L84.0267 104.447C80.7198 102.56 78.0742 97.5556 78.0742 93.2898V7.48104C78.0742 3.21521 80.7198 1.24643 84.0267 3.13324L189.271 63.429C192.578 65.3158 195.224 70.3199 195.224 74.5858V160.394C195.224 164.66 192.578 166.629 189.271 164.742Z"
                                fill="#E2DCFF" />
                            <path
                                d="M186.169 158.765L88.3349 102.263C85.2905 100.534 82.9043 95.9212 82.9043 91.9677V13.1455C82.9043 9.19204 85.3728 7.38001 88.3349 9.10965L186.169 65.6113C189.214 67.3409 191.6 71.9533 191.6 75.9067V154.729C191.6 158.682 189.132 160.494 186.169 158.765Z"
                                fill="white" />
                            <path
                                d="M186.169 158.765L88.3349 102.263C85.2905 100.534 82.9043 95.9212 82.9043 91.9677V13.1455C82.9043 9.19204 85.3728 7.38001 88.3349 9.10965L186.169 65.6113C189.214 67.3409 191.6 71.9533 191.6 75.9067V154.729C191.6 158.682 189.132 160.494 186.169 158.765Z"
                                fill="white" />
                            <path
                                d="M190.309 167.931L176.224 166.036L85.2095 113.442C82.3266 111.794 77.2199 111.959 73.8429 113.937L9.3502 151.197L0.784208 150.043V154.907C0.701842 156.061 1.36069 157.133 2.76091 157.875L105.966 217.474C108.848 219.123 113.955 218.958 117.332 216.98L187.59 176.422C189.485 175.351 190.391 173.949 190.391 172.63V167.931H190.309Z"
                                fill="#553DB4" />
                            <path
                                d="M122.758 134.165L116.212 130.435H44.7014L9.40198 150.828L0.784249 149.667V154.559C0.701386 155.719 1.36431 156.797 2.77297 157.543L106.6 217.48C108.174 218.392 110.329 218.724 112.483 218.558L122.758 134.165Z"
                                fill="#553DB4" />
                            <path
                                d="M105.965 212.607L2.73335 152.803C-0.15021 151.149 0.179341 148.171 3.55723 146.186L73.8338 105.489C77.2117 103.504 82.3197 103.339 85.2033 104.993L188.435 164.797C191.318 166.451 190.989 169.429 187.611 171.414L117.334 212.111C113.957 214.096 108.849 214.344 105.965 212.607Z"
                                fill="#E2DCFF" />
                            <path
                                d="M187.617 170.123L117.344 210.362C113.967 212.325 108.859 212.489 105.976 210.853L2.75027 151.721C1.76167 151.148 1.18504 150.494 0.937889 149.758C0.443594 151.148 1.10261 152.539 2.75027 153.52L105.976 212.652C108.859 214.288 113.967 214.124 117.344 212.161L187.617 171.922C189.923 170.614 190.747 168.896 190.253 167.342C189.923 168.324 189.017 169.305 187.617 170.123Z"
                                fill="white" />
                            <path
                                d="M46.7097 133.941L42.1072 131.154C41.7035 130.9 41.7843 130.562 42.188 130.309L47.5978 127.015C48.0016 126.761 48.6476 126.761 49.0513 126.93L53.6537 129.718C54.0574 129.971 53.9767 130.309 53.5729 130.562L48.163 133.857C47.7593 134.11 47.1134 134.11 46.7097 133.941Z"
                                fill="#553DB4" />
                            <path
                                d="M53.9557 137.529L49.3533 134.755C48.9496 134.503 49.0303 134.166 49.4341 133.914L54.844 130.636C55.2477 130.384 55.8936 130.384 56.2973 130.552L60.8998 133.326C61.3035 133.578 61.2227 133.914 60.819 134.166L55.4091 137.445C54.9247 137.697 54.2787 137.781 53.9557 137.529Z"
                                fill="#553DB4" />
                            <path
                                d="M61.2019 141.207L56.5994 138.884C56.1957 138.673 56.2765 138.392 56.6802 138.18L62.09 135.435C62.4937 135.224 63.1397 135.224 63.5435 135.365L68.1458 137.688C68.5496 137.899 68.4688 138.18 68.0651 138.392L62.6552 141.137C62.2515 141.348 61.6056 141.348 61.2019 141.207Z"
                                fill="#553DB4" />
                            <path
                                d="M71.2503 138.723L66.2826 135.704C65.8468 135.43 65.934 135.064 66.3697 134.789L71.2503 131.862C71.6861 131.587 72.3834 131.587 72.8191 131.77L77.7869 134.789C78.2227 135.064 78.1355 135.43 77.6998 135.704L72.8191 138.632C72.3834 138.906 71.6861 138.998 71.2503 138.723Z"
                                fill="#553DB4" />
                            <path
                                d="M78.4965 142.405L73.5287 139.876C73.0929 139.646 73.1801 139.34 73.6158 139.11L78.4965 136.657C78.9323 136.427 79.6294 136.427 80.0652 136.58L85.033 139.11C85.4688 139.34 85.3816 139.646 84.9458 139.876L80.0652 142.329C79.5423 142.559 78.8451 142.559 78.4965 142.405Z"
                                fill="#553DB4" />
                            <path
                                d="M85.7425 145.997L80.7748 143.481C80.339 143.253 80.4262 142.948 80.8619 142.719L85.7425 140.279C86.1783 140.05 86.8756 140.05 87.3113 140.203L92.2791 142.719C92.7148 142.948 92.6277 143.253 92.1919 143.481L87.3113 145.921C86.8756 146.15 86.1783 146.226 85.7425 145.997Z"
                                fill="#553DB4" />
                            <path
                                d="M92.9887 150.86L88.0209 148.33C87.5851 148.1 87.6722 147.794 88.108 147.564L92.9887 145.111C93.4245 144.881 94.1216 144.881 94.5574 145.035L99.5252 147.564C99.961 147.794 99.8737 148.1 99.438 148.33L94.5574 150.783C94.0345 151.013 93.3373 151.013 92.9887 150.86Z"
                                fill="#553DB4" />
                            <path
                                d="M100.235 154.45L95.267 151.934C94.8312 151.706 94.9183 151.401 95.3541 151.172L100.235 148.732C100.67 148.504 101.368 148.504 101.804 148.656L106.771 151.172C107.207 151.401 107.12 151.706 106.684 151.934L101.804 154.374C101.368 154.603 100.67 154.679 100.235 154.45Z"
                                fill="#553DB4" />
                            <path
                                d="M107.481 159.292L102.513 156.257C102.077 155.981 102.164 155.613 102.6 155.337L107.481 152.394C107.917 152.118 108.614 152.118 109.05 152.302L114.017 155.337C114.453 155.613 114.366 155.981 113.93 156.257L109.05 159.2C108.527 159.476 107.829 159.476 107.481 159.292Z"
                                fill="#553DB4" />
                            <path
                                d="M114.729 162.906L109.761 160.39C109.325 160.162 109.412 159.857 109.848 159.628L114.729 157.188C115.165 156.96 115.862 156.96 116.298 157.112L121.265 159.628C121.701 159.857 121.614 160.162 121.178 160.39L116.298 162.83C115.862 163.059 115.165 163.135 114.729 162.906Z"
                                fill="#553DB4" />
                            <path
                                d="M121.975 167.745L117.007 164.71C116.571 164.434 116.659 164.066 117.094 163.79L121.975 160.847C122.411 160.571 123.108 160.571 123.544 160.755L128.512 163.79C128.947 164.066 128.86 164.434 128.424 164.71L123.544 167.653C123.021 167.929 122.324 167.929 121.975 167.745Z"
                                fill="#553DB4" />
                            <path
                                d="M129.221 171.336L124.253 168.831C123.818 168.603 123.905 168.299 124.34 168.071L129.221 165.642C129.657 165.414 130.354 165.414 130.79 165.566L135.758 168.071C136.193 168.299 136.106 168.603 135.67 168.831L130.79 171.26C130.354 171.564 129.657 171.564 129.221 171.336Z"
                                fill="#553DB4" />
                            <path
                                d="M136.467 176.2L131.499 173.165C131.064 172.889 131.151 172.521 131.587 172.245L136.467 169.302C136.903 169.026 137.6 169.026 138.036 169.21L143.004 172.245C143.439 172.521 143.352 172.889 142.916 173.165L138.036 176.108C137.513 176.384 136.816 176.384 136.467 176.2Z"
                                fill="#553DB4" />
                            <path
                                d="M82.1195 136.335L77.1517 133.819C76.7159 133.591 76.8032 133.286 77.2389 133.057L82.1195 130.617C82.5553 130.388 83.2524 130.388 83.6882 130.541L88.656 133.057C89.0918 133.286 89.0047 133.591 88.5689 133.819L83.6882 136.259C83.2524 136.488 82.5553 136.564 82.1195 136.335Z"
                                fill="#553DB4" />
                            <path
                                d="M74.8714 131.536L69.9037 129.007C69.4679 128.777 69.5551 128.471 69.9908 128.241L74.8714 125.788C75.3072 125.558 76.0045 125.558 76.4402 125.711L81.408 128.241C81.8437 128.471 81.7566 128.777 81.3208 129.007L76.4402 131.46C76.0045 131.69 75.3072 131.69 74.8714 131.536Z"
                                fill="#553DB4" />
                            <path
                                d="M67.2037 127.841L57.7946 122.037C57.4089 121.788 57.486 121.457 57.8717 121.208L62.1906 118.555C62.5762 118.306 63.1932 118.306 63.5789 118.472L72.988 124.276C73.3736 124.525 73.2965 124.856 72.9109 125.105L68.5919 127.758C68.2063 128.09 67.5893 128.09 67.2037 127.841Z"
                                fill="#553DB4" />
                            <path
                                d="M72.4737 123.04L63.8659 118.371C63.4312 118.125 63.5181 117.798 63.9528 117.552L68.8219 114.931C69.2566 114.685 69.9522 114.685 70.3869 114.849L78.9947 119.518C79.4295 119.764 79.3425 120.091 78.9078 120.337L74.0387 122.958C73.517 123.204 72.8215 123.286 72.4737 123.04Z"
                                fill="#553DB4" />
                            <path
                                d="M89.3656 139.99L84.3978 137.461C83.9621 137.231 84.0492 136.925 84.485 136.695L89.3656 134.242C89.8013 134.012 90.4986 134.012 90.9344 134.165L95.9021 136.695C96.3379 136.925 96.2508 137.231 95.815 137.461L90.9344 139.914C90.4115 140.144 89.7142 140.144 89.3656 139.99Z"
                                fill="#553DB4" />
                            <path
                                d="M96.6117 144.761L91.6439 141.742C91.2081 141.468 91.2954 141.102 91.7311 140.827L96.6117 137.9C97.0475 137.625 97.7446 137.625 98.1804 137.808L103.148 140.827C103.584 141.102 103.497 141.468 103.061 141.742L98.1804 144.67C97.7446 144.944 97.0475 145.036 96.6117 144.761Z"
                                fill="#553DB4" />
                            <path
                                d="M103.858 148.444L98.89 145.914C98.4543 145.684 98.5414 145.378 98.9772 145.148L103.858 142.695C104.294 142.465 104.991 142.465 105.427 142.619L110.394 145.148C110.83 145.378 110.743 145.684 110.307 145.914L105.427 148.367C104.904 148.597 104.206 148.597 103.858 148.444Z"
                                fill="#553DB4" />
                            <path
                                d="M111.106 153.216L106.138 150.197C105.702 149.923 105.789 149.557 106.225 149.282L111.106 146.355C111.542 146.08 112.239 146.08 112.675 146.263L117.642 149.282C118.078 149.557 117.991 149.923 117.555 150.197L112.675 153.125C112.239 153.399 111.542 153.491 111.106 153.216Z"
                                fill="#553DB4" />
                            <path
                                d="M118.352 156.898L113.384 154.368C112.948 154.138 113.036 153.832 113.471 153.602L118.352 151.149C118.788 150.919 119.485 150.919 119.921 151.073L124.888 153.602C125.324 153.832 125.237 154.138 124.801 154.368L119.921 156.821C119.398 157.051 118.701 157.051 118.352 156.898Z"
                                fill="#553DB4" />
                            <path
                                d="M125.073 161.64L120.602 158.634C120.209 158.36 120.288 157.996 120.68 157.723L125.073 154.807C125.465 154.534 126.092 154.534 126.484 154.716L130.955 157.723C131.348 157.996 131.269 158.36 130.877 158.634L126.484 161.549C126.092 161.914 125.465 161.914 125.073 161.64Z"
                                fill="#553DB4" />
                            <path
                                d="M132.319 165.353L127.848 162.823C127.456 162.594 127.534 162.287 127.926 162.057L132.319 159.604C132.711 159.374 133.338 159.374 133.731 159.528L138.202 162.057C138.594 162.287 138.515 162.594 138.123 162.823L133.731 165.276C133.26 165.506 132.632 165.506 132.319 165.353Z"
                                fill="#553DB4" />
                            <path
                                d="M139.565 170.095L135.094 167.089C134.702 166.815 134.78 166.451 135.172 166.178L139.565 163.262C139.957 162.989 140.584 162.989 140.977 163.171L145.448 166.178C145.84 166.451 145.761 166.815 145.369 167.089L140.977 170.004C140.584 170.369 139.957 170.369 139.565 170.095Z"
                                fill="#553DB4" />
                            <path
                                d="M146.811 173.806L142.34 171.277C141.948 171.047 142.026 170.74 142.418 170.51L146.811 168.057C147.203 167.828 147.831 167.828 148.223 167.981L152.694 170.51C153.086 170.74 153.008 171.047 152.615 171.277L148.223 173.729C147.752 173.959 147.125 173.959 146.811 173.806Z"
                                fill="#553DB4" />
                            <path
                                d="M159.799 181.045L149.6 175.171C149.189 174.923 149.271 174.592 149.683 174.343L154.289 171.696C154.7 171.448 155.358 171.448 155.769 171.613L165.968 177.487C166.379 177.735 166.297 178.066 165.886 178.315L161.28 180.962C160.786 181.21 160.128 181.21 159.799 181.045Z"
                                fill="#553DB4" />
                            <path
                                d="M174.106 172.536L165.307 167.775C164.884 167.537 164.969 167.219 165.392 166.981L170.13 164.442C170.553 164.204 171.23 164.204 171.653 164.363L180.452 169.124C180.875 169.362 180.79 169.68 180.367 169.918L175.629 172.457C175.122 172.774 174.445 172.774 174.106 172.536Z"
                                fill="#553DB4" />
                            <path
                                d="M102.125 139.969L97.6543 136.934C97.2621 136.658 97.3406 136.29 97.7328 136.014L102.125 133.071C102.517 132.795 103.145 132.795 103.537 132.979L108.008 136.014C108.4 136.29 108.322 136.658 107.93 136.934L103.537 139.877C103.067 140.153 102.517 140.153 102.125 139.969Z"
                                fill="#553DB4" />
                            <path
                                d="M94.1976 135.128L89.2299 132.612C88.7941 132.383 88.8812 132.079 89.317 131.85L94.1976 129.41C94.6334 129.181 95.3306 129.181 95.7664 129.334L100.734 131.85C101.17 132.079 101.083 132.383 100.647 132.612L95.7664 135.052C95.3306 135.281 94.6334 135.357 94.1976 135.128Z"
                                fill="#553DB4" />
                            <path
                                d="M86.9496 131.476L81.9818 128.457C81.546 128.183 81.6332 127.817 82.069 127.542L86.9496 124.615C87.3853 124.34 88.0826 124.34 88.5184 124.523L93.4861 127.542C93.9219 127.817 93.8347 128.183 93.399 128.457L88.5184 131.385C87.9954 131.659 87.2982 131.751 86.9496 131.476Z"
                                fill="#553DB4" />
                            <path
                                d="M79.7035 126.649L74.7357 124.143C74.3 123.915 74.3871 123.612 74.8229 123.384L79.7035 120.954C80.1392 120.726 80.8365 120.726 81.2723 120.878L86.24 123.384C86.6758 123.612 86.5886 123.915 86.1529 124.143L81.2723 126.573C80.7494 126.877 80.0521 126.877 79.7035 126.649Z"
                                fill="#553DB4" />
                            <path
                                d="M109.371 143.557L104.9 141.051C104.508 140.824 104.587 140.52 104.979 140.292L109.371 137.862C109.764 137.635 110.391 137.635 110.783 137.786L115.254 140.292C115.647 140.52 115.568 140.824 115.176 141.051L110.783 143.481C110.313 143.785 109.685 143.785 109.371 143.557Z"
                                fill="#553DB4" />
                            <path
                                d="M116.617 148.423L112.147 145.388C111.754 145.112 111.833 144.744 112.225 144.468L116.617 141.525C117.01 141.249 117.637 141.249 118.029 141.433L122.5 144.468C122.893 144.744 122.814 145.112 122.422 145.388L118.029 148.331C117.637 148.607 117.01 148.607 116.617 148.423Z"
                                fill="#553DB4" />
                            <path
                                d="M123.866 152.012L119.395 149.506C119.002 149.279 119.081 148.975 119.473 148.747L123.866 146.317C124.258 146.09 124.885 146.09 125.277 146.242L129.748 148.747C130.141 148.975 130.062 149.279 129.67 149.506L125.277 151.936C124.807 152.24 124.179 152.24 123.866 152.012Z"
                                fill="#553DB4" />
                            <path
                                d="M130.428 155.69L125.46 153.16C125.025 152.93 125.112 152.624 125.547 152.394L130.428 149.941C130.864 149.711 131.561 149.711 131.997 149.865L136.965 152.394C137.4 152.624 137.313 152.93 136.877 153.16L131.997 155.613C131.561 155.843 130.864 155.843 130.428 155.69Z"
                                fill="#553DB4" />
                            <path
                                d="M138.356 160.465L133.885 157.96C133.493 157.732 133.571 157.428 133.963 157.2L138.356 154.771C138.748 154.543 139.375 154.543 139.768 154.695L144.239 157.2C144.631 157.428 144.552 157.732 144.16 157.96L139.768 160.389C139.297 160.693 138.67 160.693 138.356 160.465Z"
                                fill="#553DB4" />
                            <path
                                d="M144.922 164.112L139.954 161.597C139.519 161.368 139.606 161.063 140.042 160.834L144.922 158.394C145.358 158.166 146.055 158.166 146.491 158.318L151.459 160.834C151.895 161.063 151.807 161.368 151.372 161.597L146.491 164.036C146.055 164.265 145.358 164.341 144.922 164.112Z"
                                fill="#553DB4" />
                            <path
                                d="M152.168 168.887L147.201 165.881C146.765 165.607 146.852 165.243 147.288 164.97L152.168 162.054C152.604 161.781 153.301 161.781 153.737 161.963L158.705 164.97C159.141 165.243 159.053 165.607 158.618 165.881L153.737 168.796C153.214 169.161 152.517 169.161 152.168 168.887Z"
                                fill="#553DB4" />
                            <path
                                d="M159.414 172.568L154.447 170.052C154.011 169.823 154.098 169.518 154.534 169.289L159.414 166.849C159.85 166.621 160.547 166.621 160.983 166.773L165.951 169.289C166.387 169.518 166.3 169.823 165.864 170.052L160.983 172.491C160.547 172.72 159.85 172.796 159.414 172.568Z"
                                fill="#553DB4" />
                            <path
                                d="M166.661 177.342L161.693 174.335C161.257 174.062 161.344 173.697 161.78 173.424L166.661 170.508C167.096 170.235 167.794 170.235 168.229 170.417L173.197 173.424C173.633 173.697 173.546 174.062 173.11 174.335L168.229 177.25C167.706 177.615 167.009 177.615 166.661 177.342Z"
                                fill="#553DB4" />
                            <path
                                d="M97.8187 129.121L92.8509 126.592C92.4151 126.362 92.5024 126.055 92.9382 125.826L97.8187 123.373C98.2545 123.143 98.9517 123.143 99.3874 123.296L104.355 125.826C104.791 126.055 104.704 126.362 104.268 126.592L99.3874 129.045C98.8645 129.275 98.1673 129.275 97.8187 129.121Z"
                                fill="#553DB4" />
                            <path
                                d="M91.2562 125.408L86.7852 122.401C86.393 122.128 86.4714 121.764 86.8636 121.49L91.2562 118.575C91.6484 118.301 92.2759 118.301 92.6681 118.483L97.1391 121.49C97.5312 121.764 97.4528 122.128 97.0606 122.401L92.6681 125.317C92.1975 125.681 91.5699 125.681 91.2562 125.408Z"
                                fill="#553DB4" />
                            <path
                                d="M84.0101 120.635L79.5391 118.119C79.1469 117.89 79.2253 117.585 79.6175 117.357L84.0101 114.917C84.4023 114.688 85.0298 114.688 85.422 114.841L89.893 117.357C90.2852 117.585 90.2067 117.89 89.8145 118.119L85.422 120.559C84.9514 120.787 84.3238 120.864 84.0101 120.635Z"
                                fill="#553DB4" />
                            <path
                                d="M76.764 117.021L72.293 113.986C71.9008 113.711 71.9792 113.343 72.3714 113.067L76.764 110.123C77.1562 109.848 77.7837 109.848 78.1759 110.032L82.6469 113.067C83.0391 113.343 82.9606 113.711 82.5684 113.986L78.1759 116.93C77.7052 117.205 77.0778 117.205 76.764 117.021Z"
                                fill="#553DB4" />
                            <path
                                d="M84.299 112.168L79.5549 109.429C79.1387 109.18 79.2219 108.848 79.6381 108.599L81.2195 107.686C81.6356 107.437 82.3015 107.437 82.7177 107.603L87.4618 110.342C87.8779 110.591 87.7947 110.923 87.3785 111.172L85.7971 112.085C85.381 112.334 84.7151 112.417 84.299 112.168Z"
                                fill="#553DB4" />
                            <path
                                d="M93.2442 118.241L89.1779 115.482C88.8212 115.231 88.8925 114.897 89.2492 114.646L90.6047 113.726C90.9614 113.476 91.5321 113.476 91.8888 113.643L95.9552 116.402C96.3119 116.652 96.2406 116.987 95.8839 117.238L94.5284 118.157C94.1004 118.408 93.5296 118.408 93.2442 118.241Z"
                                fill="#553DB4" />
                            <path
                                d="M99.9983 121.864L95.2541 119.105C94.8379 118.854 94.9211 118.52 95.3373 118.269L96.9187 117.35C97.3348 117.099 98.0007 117.099 98.4169 117.266L103.161 120.025C103.577 120.275 103.494 120.61 103.078 120.861L101.496 121.78C100.997 122.031 100.331 122.031 99.9983 121.864Z"
                                fill="#553DB4" />
                            <path
                                d="M107.244 126.661L102.5 123.922C102.084 123.673 102.167 123.341 102.583 123.092L104.165 122.179C104.581 121.93 105.247 121.93 105.663 122.096L110.407 124.835C110.823 125.084 110.74 125.416 110.324 125.665L108.742 126.578C108.326 126.827 107.661 126.91 107.244 126.661Z"
                                fill="#553DB4" />
                            <path
                                d="M114.492 130.285L109.748 127.546C109.332 127.297 109.415 126.965 109.831 126.716L111.413 125.803C111.829 125.554 112.495 125.554 112.911 125.72L117.655 128.459C118.071 128.708 117.988 129.04 117.572 129.289L115.991 130.202C115.574 130.451 114.908 130.534 114.492 130.285Z"
                                fill="#553DB4" />
                            <path
                                d="M154.348 153.264L149.604 150.506C149.188 150.255 149.271 149.92 149.687 149.67L151.268 148.75C151.684 148.499 152.35 148.499 152.766 148.666L157.511 151.425C157.927 151.676 157.843 152.01 157.427 152.261L155.846 153.181C155.43 153.431 154.764 153.431 154.348 153.264Z"
                                fill="#553DB4" />
                            <path
                                d="M161.594 158.096L156.85 155.338C156.434 155.087 156.517 154.752 156.933 154.502L158.514 153.582C158.931 153.331 159.596 153.331 160.013 153.498L164.757 156.257C165.173 156.508 165.09 156.842 164.673 157.093L163.092 158.013C162.593 158.263 161.927 158.263 161.594 158.096Z"
                                fill="#553DB4" />
                            <path
                                d="M168.838 161.72L164.094 158.962C163.678 158.711 163.761 158.376 164.177 158.126L165.758 157.206C166.175 156.955 166.841 156.955 167.257 157.122L172.001 159.881C172.417 160.132 172.334 160.466 171.918 160.717L170.336 161.637C169.837 161.887 169.171 161.887 168.838 161.72Z"
                                fill="#553DB4" />
                            <path
                                d="M176.086 165.341L171.342 162.583C170.926 162.332 171.009 161.998 171.425 161.747L173.007 160.827C173.423 160.576 174.089 160.576 174.505 160.743L179.249 163.502C179.665 163.753 179.582 164.087 179.166 164.338L177.584 165.258C177.168 165.509 176.502 165.509 176.086 165.341Z"
                                fill="#553DB4" />
                            <path
                                d="M105.067 133.864L100.099 130.857C99.6632 130.584 99.7504 130.22 100.186 129.946L105.067 127.031C105.503 126.757 106.2 126.757 106.636 126.939L111.603 129.946C112.039 130.22 111.952 130.584 111.516 130.857L106.636 133.773C106.2 134.137 105.503 134.137 105.067 133.864Z"
                                fill="#553DB4" />
                            <path
                                d="M112.313 137.574L107.345 135.045C106.909 134.815 106.997 134.509 107.432 134.279L112.313 131.826C112.749 131.596 113.446 131.596 113.882 131.749L118.849 134.279C119.285 134.509 119.198 134.815 118.762 135.045L113.882 137.498C113.359 137.728 112.661 137.728 112.313 137.574Z"
                                fill="#553DB4" />
                            <path
                                d="M119.559 142.318L114.591 139.311C114.155 139.038 114.243 138.674 114.678 138.4L119.559 135.485C119.995 135.211 120.692 135.211 121.128 135.394L126.095 138.4C126.531 138.674 126.444 139.038 126.008 139.311L121.128 142.227C120.692 142.591 119.995 142.591 119.559 142.318Z"
                                fill="#553DB4" />
                            <path
                                d="M126.805 146.028L121.837 143.499C121.401 143.269 121.489 142.963 121.924 142.733L126.805 140.28C127.241 140.05 127.938 140.05 128.374 140.204L133.342 142.733C133.777 142.963 133.69 143.269 133.254 143.499L128.374 145.952C127.851 146.182 127.241 146.182 126.805 146.028Z"
                                fill="#553DB4" />
                            <path
                                d="M134.051 149.596L129.083 147.09C128.648 146.863 128.735 146.559 129.171 146.331L134.051 143.901C134.487 143.674 135.184 143.674 135.62 143.826L140.588 146.331C141.023 146.559 140.936 146.863 140.501 147.09L135.62 149.52C135.097 149.824 134.4 149.824 134.051 149.596Z"
                                fill="#553DB4" />
                            <path
                                d="M141.297 154.424L136.329 151.405C135.894 151.131 135.981 150.765 136.417 150.49L141.297 147.563C141.733 147.288 142.43 147.288 142.866 147.471L147.834 150.49C148.27 150.765 148.182 151.131 147.747 151.405L142.866 154.333C142.43 154.607 141.733 154.699 141.297 154.424Z"
                                fill="#553DB4" />
                            <path
                                d="M148.545 158.05L143.578 155.545C143.142 155.317 143.229 155.013 143.665 154.785L148.545 152.356C148.981 152.128 149.678 152.128 150.114 152.28L155.082 154.785C155.518 155.013 155.43 155.317 154.995 155.545L150.114 157.974C149.591 158.278 148.894 158.278 148.545 158.05Z"
                                fill="#553DB4" />
                            <path
                                d="M155.791 162.878L150.824 159.858C150.388 159.584 150.475 159.218 150.911 158.944L155.791 156.016C156.227 155.741 156.924 155.741 157.36 155.924L162.328 158.944C162.764 159.218 162.677 159.584 162.241 159.858L157.36 162.786C156.924 163.061 156.227 163.152 155.791 162.878Z"
                                fill="#553DB4" />
                            <path
                                d="M163.037 166.503L158.07 163.998C157.634 163.77 157.721 163.466 158.157 163.238L163.037 160.809C163.473 160.581 164.17 160.581 164.606 160.733L169.574 163.238C170.01 163.466 169.923 163.77 169.487 163.998L164.606 166.427C164.083 166.731 163.386 166.731 163.037 166.503Z"
                                fill="#553DB4" />
                            <path
                                d="M64.3601 133.877L50.5664 125.742C50.1535 125.488 50.236 125.149 50.649 124.895L55.2744 122.183C55.6874 121.929 56.3482 121.929 56.7612 122.099L70.5548 130.233C70.9678 130.487 70.8852 130.826 70.4722 131.08L65.8468 133.792C65.4338 134.131 64.773 134.131 64.3601 133.877Z"
                                fill="#553DB4" />
                            <path
                                d="M152.526 184.62L138.732 177.299C138.319 177.07 138.402 176.765 138.815 176.536L143.44 174.096C143.853 173.867 144.514 173.867 144.927 174.019L158.721 181.34C159.134 181.569 159.051 181.874 158.638 182.103L154.013 184.543C153.6 184.848 152.939 184.848 152.526 184.62Z"
                                fill="#553DB4" />
                            <path
                                d="M123.602 177.788L119 175C118.596 174.747 118.677 174.409 119.081 174.156L124.49 170.861C124.894 170.608 125.54 170.608 125.944 170.777L130.546 173.564C130.95 173.818 130.869 174.156 130.466 174.409L125.056 177.703C124.652 177.957 124.006 177.957 123.602 177.788Z"
                                fill="#553DB4" />
                            <path
                                d="M71.6441 147.23L63.8612 143.035C63.4336 142.793 63.5191 142.47 63.9467 142.228L69.6771 139.082C70.1047 138.84 70.7889 138.84 71.2165 139.001L78.9994 143.196C79.427 143.438 79.3415 143.761 78.9139 144.003L73.1837 147.15C72.6705 147.392 71.9862 147.392 71.6441 147.23Z"
                                fill="#553DB4" />
                            <path
                                d="M116.918 173.769L109.734 169.59C109.339 169.349 109.418 169.027 109.813 168.786L115.103 165.652C115.497 165.411 116.129 165.411 116.524 165.572L123.708 169.751C124.103 169.992 124.024 170.313 123.629 170.554L118.339 173.688C117.866 173.929 117.234 174.01 116.918 173.769Z"
                                fill="#553DB4" />
                            <path
                                d="M81.8929 153.269L74.7087 149.074C74.314 148.832 74.3929 148.509 74.7877 148.267L80.0772 145.121C80.4719 144.879 81.1035 144.879 81.4982 145.04L88.6825 149.235C89.0772 149.477 88.9982 149.8 88.6035 150.042L83.314 153.189C82.8403 153.431 82.2087 153.431 81.8929 153.269Z"
                                fill="#553DB4" />
                            <path
                                d="M81.5346 144.928L113.995 163.663C114.407 163.91 114.407 164.238 113.995 164.485L108.393 167.69C107.981 167.936 107.322 167.936 106.91 167.69L74.4492 148.954L81.5346 144.928Z"
                                fill="#553DB4" />
                            <path
                                d="M142.57 185.087L147.8 187.929C148.114 188.088 148.036 188.405 147.722 188.564L145.605 189.834C145.292 189.993 144.743 190.072 144.43 189.913L139.2 187.071C138.886 186.912 138.964 186.595 139.278 186.436L141.394 185.166C141.786 185.007 142.257 184.928 142.57 185.087Z"
                                fill="#553DB4" />
                            <path
                                d="M135.57 181.087L140.8 183.929C141.114 184.088 141.036 184.405 140.722 184.564L138.605 185.834C138.292 185.993 137.743 186.072 137.43 185.913L132.2 183.071C131.886 182.912 131.964 182.595 132.278 182.436L134.394 181.166C134.786 181.007 135.257 180.928 135.57 181.087Z"
                                fill="#553DB4" />
                            <path
                                d="M139.641 178.673L144.871 181.516C145.184 181.675 145.106 181.992 144.792 182.151L142.676 183.421C142.362 183.58 141.814 183.659 141.5 183.5L136.27 180.658C135.956 180.499 136.035 180.182 136.348 180.023L138.465 178.753C138.857 178.594 139.327 178.515 139.641 178.673Z"
                                fill="#553DB4" />
                            <path
                                d="M128.57 177.087L133.8 179.929C134.114 180.088 134.036 180.405 133.722 180.564L131.605 181.834C131.292 181.993 130.743 182.072 130.43 181.913L125.2 179.071C124.886 178.912 124.964 178.595 125.278 178.436L127.394 177.166C127.786 177.007 128.257 176.928 128.57 177.087Z"
                                fill="#553DB4" />
                            <path
                                d="M183.634 209.439L191.637 209.559C192.218 208.838 193.057 208.177 194.154 207.576C198.543 205.234 205.449 205.054 210.225 207.036L214.937 202.891L222.23 203.011C221.843 202.771 221.52 202.591 221.133 202.351C211 196.884 194.542 196.884 184.409 202.351C182.537 203.372 181.052 204.453 179.826 205.654L183.634 209.439Z"
                                fill="#A99DD8" />
                            <path d="M209.98 196.092V207.673L214.692 203.169V191.653L209.98 196.092Z" fill="#A99DD8" />
                            <path
                                d="M191.171 197.988V209.557L201.788 201.025L209.981 206.843V195.273L202.444 191.653C202.444 191.653 192.023 194.82 191.499 194.95C190.974 195.079 191.171 197.988 191.171 197.988Z"
                                fill="#A99DD8" />
                            <path d="M183.596 198.251V209.496L191.134 209.559V198.376L183.596 198.251Z" fill="#A99DD8" />
                            <path d="M179.826 194.48V205.649L183.595 209.557V198.389L179.826 194.48Z" fill="#A99DD8" />
                            <path
                                d="M183.634 198.12L191.637 198.25C192.218 197.469 193.057 196.753 194.154 196.103C198.543 193.565 205.449 193.37 210.225 195.517L214.937 191.027L222.23 191.157C221.843 190.897 221.52 190.702 221.133 190.441C211 184.52 194.542 184.52 184.409 190.441C182.537 191.547 181.052 192.719 179.826 194.02L183.634 198.12Z"
                                fill="#E2DCFF" />
                            <path
                                d="M207.152 207.673V219.153L207.932 219.923L212.806 217.165C212.806 217.165 212.026 208.378 211.961 208.186C211.831 208.058 207.152 207.673 207.152 207.673Z"
                                fill="#A99DD8" />
                            <path
                                d="M216.717 204.847L212.459 208.755C215.996 211.51 215.538 215.354 211.214 217.788C210.035 218.493 208.659 218.941 207.152 219.326L211.542 223.874L207.676 227.462C212.59 226.886 217.306 225.54 221.106 223.426C229.95 218.429 231.195 210.805 224.84 205.039L216.717 204.847Z"
                                fill="#A99DD8" />
                            <path
                                d="M208.096 216.081V227.462L217.531 223.902L228.827 213.22L228.762 201.077L216.824 210.487L208.096 216.081Z"
                                fill="#A99DD8" />
                            <path
                                d="M216.717 193.538L212.459 197.446C215.996 200.201 215.538 204.045 211.214 206.479C210.035 207.184 208.659 207.633 207.152 208.017L211.542 212.566L207.676 216.154C212.59 215.577 217.306 214.232 221.106 212.117C229.95 207.12 231.195 199.496 224.84 193.73L216.717 193.538Z"
                                fill="#E2DCFF" />
                            <path
                                d="M208.096 223.51L204.281 219.438C200.725 219.632 197.105 218.985 194.389 217.434C191.609 215.818 190.445 213.62 190.898 211.487L181.846 211.357L178.354 207.673C175.315 212.909 177.384 218.921 184.626 223.058C189.928 226.096 196.975 227.583 203.894 227.454L208.096 223.51Z"
                                fill="#EF8F00" />
                            <path d="M203.385 215.878V227.462L177 212.596V201.077L180.869 202.622L203.385 215.878Z"
                                fill="#EF8F00" />
                            <path d="M208.096 212.385V223.646L203.385 227.462V216.201L208.096 212.385Z" fill="#D78100" />
                            <path
                                d="M208.096 212.204L204.281 208.131C200.725 208.325 197.105 207.679 194.389 206.127C191.609 204.511 190.445 202.313 190.898 200.18L181.846 200.051L178.354 196.366C175.315 201.602 177.384 207.614 184.626 211.751C189.928 214.79 196.975 216.276 203.894 216.147L208.096 212.204Z"
                                fill="#FFAD00" />
                            <path
                                d="M39.2342 80.1653L21.592 70.1712C21.2575 70.0074 20.9231 69.9255 20.6722 70.0893L19 71.4355C19 71.4355 19.7525 71.3181 20.1706 71.482V84.0973C20.1706 85.1623 20.8394 86.3092 21.592 86.7188L38.4817 96.3032V97.7778L40.2375 96.7947C40.5719 96.6309 40.7391 96.2214 40.7391 95.6479V82.7867C40.6555 81.8036 40.0703 80.5749 39.2342 80.1653Z"
                                fill="#FFAD00" />
                            <path
                                d="M38.1067 97.5899L20.4246 87.7144C19.6704 87.3097 19 86.0955 19 85.1241V72.4155C19 70.9355 19.2458 71.0311 20 71.4358L38.1067 81.2387C38.8609 81.6434 39.5314 82.8577 39.5314 83.829V96.5376C39.5314 97.5899 38.9447 98.0756 38.1067 97.5899Z"
                                fill="#EF8F00" />
                            <path
                                d="M29.8686 85.0435C29.8686 85.6254 29.351 85.8841 28.6609 85.5608C27.9707 85.2375 27.4531 84.5262 27.4531 83.9443C27.4531 83.3623 27.9707 83.1037 28.6609 83.427C29.351 83.7503 29.8686 84.4615 29.8686 85.0435Z"
                                fill="white" />
                            <path
                                d="M26.4155 83.1929C26.4155 83.7748 25.8978 84.0335 25.2077 83.7102C24.5176 83.3869 24 82.6756 24 82.0937C24 81.5118 24.5176 81.2531 25.2077 81.5764C25.8978 81.8997 26.4155 82.6109 26.4155 83.1929Z"
                                fill="white" />
                            <path
                                d="M33.4936 87.4595C33.4936 88.0414 32.976 88.3001 32.2859 87.9768C31.5957 87.6535 31.0781 86.9422 31.0781 86.3603C31.0781 85.7784 31.5957 85.5197 32.2859 85.843C32.976 86.1663 33.4936 86.8775 33.4936 87.4595Z"
                                fill="white" />
                            <g class="books">
                                <path
                                    d="M127.663 103.288C129.958 104.579 129.958 106.731 127.663 108.094L88.7132 130.546C88.0676 130.904 87.0633 130.904 86.4178 130.546C85.7722 130.187 85.7722 129.613 86.4178 129.255L125.367 106.803C126.371 106.229 126.371 105.225 125.367 104.651L121.565 102.499C121.996 101.854 122.354 101.208 122.713 100.562L127.663 103.288Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M72.5037 121.222L111.453 98.7701C113.749 97.479 117.478 97.479 119.774 98.7701L122.787 100.492C122.428 101.137 122.069 101.783 121.639 102.428L117.478 100.061C116.474 99.4874 114.753 99.4874 113.748 100.061L74.7991 122.513C74.1536 122.871 73.1493 122.871 72.5037 122.513C71.8581 122.154 71.8581 121.58 72.5037 121.222Z"
                                    fill="#A99DD8" />
                                <path
                                    d="M72 121.867V67.1371L112.814 44.3987L118.553 44.04L124.004 47.6265L127.447 50.5675L87.7089 113.618L74.7258 121.222L72 121.867Z"
                                    fill="#A99DD8" />
                                <path d="M87.1355 128.825L74.9414 121.795V68.8584L87.4942 74.7403L87.1355 128.825Z"
                                    fill="#C3B4FF" />
                                <path d="M129.313 50.998V105.656L86.4898 130.116L85.916 129.901V75.1711L129.313 50.998Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M105.283 51.4273L113.819 46.5497C114.823 46.0476 116.401 46.1193 117.333 46.6214L125.224 51.2121C125.869 51.5708 126.084 52.0729 125.941 52.5033L104.422 65.3429L87.135 75.959L74.8691 68.8577L105.283 51.4273Z"
                                    fill="white" />
                                <path
                                    d="M87.494 74.3096V128.609V129.757V130.833C87.1354 130.833 86.705 130.761 86.4181 130.546C86.1312 130.402 85.9877 130.116 85.916 129.9V75.1703L87.494 74.3096Z"
                                    fill="#A99DD8" />
                                <path d="M72 67.854V67.1367H72.2152L72.2869 67.6388L72 67.854Z" fill="white" />
                                <path d="M85.916 75.8872V75.1699L86.1312 75.4568L85.916 75.8872Z" fill="white" />
                                <path
                                    d="M88.7128 75.8165L127.662 53.365C129.958 52.0738 129.958 49.9219 127.662 48.5591L119.772 43.9684C117.477 42.6772 113.747 42.6772 111.451 43.9684L72.5018 66.4198C71.8562 66.7785 71.8562 67.3523 72.5018 67.711C73.1473 68.0696 74.1516 68.0696 74.7972 67.711L113.747 45.2595C114.751 44.6857 116.472 44.6857 117.477 45.2595L125.367 49.8502C126.371 50.4241 126.371 51.4283 125.367 52.0021L86.4174 74.4536C85.7718 74.8123 85.7718 75.3861 86.4174 75.7448C87.063 76.1751 88.0672 76.1751 88.7128 75.8165Z"
                                    fill="#E2DCFF" />
                                <path d="M98.8281 101.71L119.056 90.0179V69.79L98.8281 81.4103V101.71Z" fill="white" />
                                <path
                                    d="M72.5023 67.7823C73.1479 68.1409 74.1522 68.1409 74.7977 67.7823L113.747 45.3308C114.751 44.7569 116.473 44.7569 117.477 45.3308L125.367 49.9215C126.013 50.2801 126.3 50.854 126.085 51.3561C125.941 51.0692 125.726 50.854 125.367 50.6388L117.477 46.0481C116.473 45.4742 114.751 45.4742 113.747 46.0481L74.7977 68.4996C74.1522 68.8582 73.1479 68.8582 72.5023 68.4996C72.0002 68.2126 71.9285 67.854 72.1437 67.4953C72.2154 67.5671 72.3589 67.7105 72.5023 67.7823Z"
                                    fill="white" />
                                <path
                                    d="M86.4164 75.8164C87.062 76.175 88.0662 76.175 88.7118 75.8164L127.661 53.3649C128.665 52.791 129.239 52.0737 129.311 51.3564C129.526 52.3607 128.952 53.3649 127.661 54.0822L88.7118 76.5337C88.0662 76.8923 87.062 76.8923 86.4164 76.5337C85.9143 76.2468 85.8425 75.8881 86.0577 75.5295C86.1295 75.6012 86.2729 75.7447 86.4164 75.8164Z"
                                    fill="white" />
                                <path
                                    d="M145.524 113.833C147.819 115.124 147.819 117.276 145.524 118.639L106.574 141.091C105.929 141.449 104.925 141.449 104.279 141.091C103.634 140.732 103.634 140.158 104.279 139.799L143.229 117.348C144.233 116.774 144.233 115.77 143.229 115.196L139.427 113.044C139.857 112.399 140.216 111.753 140.574 111.107L145.524 113.833Z"
                                    fill="#FFAD00" />
                                <path
                                    d="M90.364 131.766L129.313 109.314C131.609 108.023 135.339 108.023 137.634 109.314L140.647 111.036C140.288 111.681 139.929 112.327 139.499 112.972L135.339 110.605C134.335 110.031 132.613 110.031 131.609 110.605L92.6594 133.057C92.0138 133.415 91.0095 133.415 90.364 133.057C89.7901 132.77 89.7901 132.124 90.364 131.766Z"
                                    fill="#EF8F00" />
                                <path
                                    d="M89.9336 132.482V77.7523L130.748 55.0139L136.415 54.6553L141.938 58.17L145.309 61.1827L105.571 124.162L92.5876 131.837L89.9336 132.482Z"
                                    fill="#EF8F00" />
                                <path d="M104.997 139.368L92.8027 132.339V79.4736L105.355 85.2838L104.997 139.368Z"
                                    fill="#FFCD63" />
                                <path d="M147.244 61.541V116.199L104.421 140.731L103.848 140.516V85.7858L147.244 61.541Z"
                                    fill="#FFAD00" />
                                <path
                                    d="M123.144 62.0435L131.68 57.1659C132.684 56.6638 134.262 56.7355 135.195 57.2376L143.085 61.8283C143.73 62.187 143.946 62.6891 143.802 63.1195L122.283 75.9591L104.996 86.5752L92.7305 79.4739L123.144 62.0435Z"
                                    fill="white" />
                                <path
                                    d="M105.353 84.9258V139.225V140.373V141.449C104.995 141.449 104.564 141.377 104.277 141.162C103.991 141.019 103.847 140.732 103.775 140.517V85.7865L105.353 84.9258Z"
                                    fill="#EF8F00" />
                                <path d="M89.9336 78.3985V77.7529H90.1488L90.2205 78.1833L89.9336 78.3985Z" fill="white" />
                                <path d="M103.848 86.5044V85.7871L104.063 86.074L103.848 86.5044Z" fill="white" />
                                <path
                                    d="M106.574 86.4317L145.523 63.9802C147.819 62.6891 147.819 60.5372 145.523 59.1743L137.633 54.5836C135.338 53.2924 131.608 53.2924 129.313 54.5836L90.3631 77.0351C89.7175 77.3937 89.7175 77.9676 90.3631 78.3262C91.0087 78.6849 92.0129 78.6849 92.6585 78.3262L131.608 55.8747C132.612 55.3009 134.334 55.3009 135.338 55.8747L143.228 60.4655C144.232 61.0393 144.232 62.0435 143.228 62.6174L104.279 85.0688C103.633 85.4275 103.633 86.0013 104.279 86.36C104.924 86.7904 106 86.7904 106.574 86.4317Z"
                                    fill="#FFAD00" />
                                <path d="M116.76 112.328L136.988 100.636V80.3359L116.76 92.0279V112.328Z" fill="white" />
                                <path
                                    d="M90.3617 78.3985C91.0073 78.7571 92.0115 78.7571 92.6571 78.3985L131.606 55.947C132.611 55.3731 134.332 55.3731 135.336 55.947L143.227 60.5377C143.872 60.8964 144.159 61.4702 143.944 61.9723C143.801 61.6854 143.585 61.4702 143.227 61.255L135.336 56.6643C134.332 56.0904 132.611 56.0904 131.606 56.6643L92.6571 79.1158C92.0115 79.4744 91.0073 79.4744 90.3617 79.1158C89.8596 78.8289 89.7879 78.4702 90.0031 78.1116C90.1465 78.1833 90.2182 78.255 90.3617 78.3985Z"
                                    fill="white" />
                                <path
                                    d="M104.35 86.4326C104.996 86.7912 106 86.7912 106.645 86.4326L145.595 63.9811C146.599 63.4073 147.173 62.69 147.245 61.9727C147.46 62.9769 146.886 63.9811 145.595 64.6984L106.645 87.1499C106 87.5085 104.996 87.5085 104.35 87.1499C103.848 86.863 103.776 86.5043 103.991 86.1457C104.063 86.2174 104.135 86.2891 104.35 86.4326Z"
                                    fill="white" />
                                <path
                                    d="M163.887 125.167C166.183 126.458 166.183 128.61 163.887 129.973L124.938 152.425C124.292 152.783 123.288 152.783 122.642 152.425C121.997 152.066 121.997 151.492 122.642 151.133L161.664 128.682C162.668 128.108 162.668 127.104 161.664 126.53L157.862 124.378C158.292 123.733 158.651 123.087 159.01 122.441L163.887 125.167Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M108.797 143.1L147.746 120.648C150.041 119.357 153.771 119.357 156.067 120.648L159.079 122.37C158.721 123.015 158.362 123.661 157.932 124.306L153.771 121.939C152.767 121.365 151.046 121.365 150.041 121.939L111.092 144.391C110.446 144.749 109.442 144.749 108.797 144.391C108.151 144.032 108.151 143.458 108.797 143.1Z"
                                    fill="#A99DD8" />
                                <path
                                    d="M108.295 143.815V89.0854L149.109 66.3469L154.776 65.9883L160.299 69.5031L163.742 72.444L123.932 135.495L111.021 143.17L108.295 143.815Z"
                                    fill="#A99DD8" />
                                <path d="M123.43 150.702L111.236 143.673V90.8076L123.789 96.6178L123.43 150.702Z"
                                    fill="#C3B4FF" />
                                <path d="M165.608 72.876V127.534L122.785 152.066L122.211 151.851V97.1207L165.608 72.876Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M141.578 73.3775L150.114 68.4999C151.118 67.9977 152.696 68.0695 153.628 68.5716L161.519 73.1623C162.164 73.521 162.379 74.0231 162.236 74.4535L140.717 87.2931L123.43 97.9092L111.164 90.8079L141.578 73.3775Z"
                                    fill="white" />
                                <path
                                    d="M123.789 96.2588V150.558V151.706V152.782C123.43 152.782 123 152.71 122.713 152.495C122.426 152.352 122.283 152.065 122.211 151.85V97.1195L123.789 96.2588Z"
                                    fill="#A99DD8" />
                                <path d="M108.295 89.7315V89.0859H108.51L108.582 89.5163L108.295 89.7315Z" fill="white" />
                                <path d="M122.211 97.7647V97.1191L122.426 97.4061L122.211 97.7647Z" fill="white" />
                                <path
                                    d="M125.008 97.7647L163.957 75.3132C166.252 74.0221 166.252 71.8702 163.957 70.5073L156.067 65.9166C153.771 64.6255 150.041 64.6255 147.746 65.9166L108.797 88.3681C108.151 88.7267 108.151 89.3006 108.797 89.6592C109.442 90.0179 110.446 90.0179 111.092 89.6592L150.041 67.2077C151.046 66.6339 152.767 66.6339 153.771 67.2077L161.662 71.7985C162.666 72.3723 162.666 73.3765 161.662 73.9504L122.712 96.4019C122.067 96.7605 122.067 97.3344 122.712 97.693C123.358 98.1234 124.362 98.1234 125.008 97.7647Z"
                                    fill="#E2DCFF" />
                                <path d="M140 120.992L160.228 109.3V89L140 100.692V120.992Z" fill="white" />
                                <path
                                    d="M108.797 89.6602C109.443 90.0188 110.447 90.0188 111.093 89.6602L150.042 67.2087C151.046 66.6349 152.768 66.6349 153.772 67.2087L161.662 71.7994C162.308 72.1581 162.595 72.7319 162.38 73.234C162.236 72.9471 162.021 72.7319 161.662 72.5167L153.772 67.926C152.768 67.3522 151.046 67.3522 150.042 67.926L111.093 90.3775C110.447 90.7361 109.443 90.7361 108.797 90.3775C108.295 90.0906 108.223 89.7319 108.439 89.3733C108.51 89.5167 108.654 89.5885 108.797 89.6602Z"
                                    fill="white" />
                                <path
                                    d="M122.713 97.7656C123.359 98.1243 124.363 98.1243 125.009 97.7656L163.958 75.3141C164.962 74.7403 165.536 74.023 165.608 73.3057C165.823 74.3099 165.249 75.3141 163.958 76.0314L125.009 98.4829C124.363 98.8416 123.359 98.8416 122.713 98.4829C122.211 98.196 122.139 97.8373 122.355 97.4787C122.426 97.5504 122.57 97.6221 122.713 97.7656Z"
                                    fill="white" />
                            </g>
                            <path
                                d="M83.5 39V20.405C83.5 14.2358 90.1877 10.3882 95.521 13.4889L183.193 64.4613C188.026 67.2712 191 72.4396 191 78.03V124.037C191 140.46 173.217 150.719 159 142.5V81.5L83.5 39Z"
                                fill="white" fill-opacity="0.75" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 ">
                <div class="anim-block brd__none">
                    <h2>Закупки <br> для бизнеса</h2>
                    <p>
                        Узнайте по каким ценам покупает товары государство, недропользователи.
                    </p>
                    <a href="#" class="anim__btn">Перейти</a>
                    <div class="anim__image">
                        <svg width="268" height="243" viewBox="0 0 268 243" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="268"
                                height="243">
                                <rect width="268" height="243" transform="matrix(-1 0 0 1 268 0)" fill="#C4C4C4" />
                            </mask>
                            <g mask="url(#mask0)">
                                <path
                                    d="M248.971 61.4418L143.761 0.716999C142.439 -0.0265704 141.199 -0.191788 140.125 0.221306L139.959 0.303905H139.877L136.488 1.95628L140.207 3.77386V3.93915V90.3584C140.207 94.6546 142.852 99.6943 146.158 101.595L248.558 160.667L249.136 165.459L252.855 163.641C254.095 162.898 254.839 161.245 254.839 158.932V72.5128C255.004 68.3818 252.277 63.3421 248.971 61.4418Z"
                                    fill="#553DB4" />
                                <path
                                    d="M248.322 61.6478L143.719 0.719403C142.405 -0.0266594 141.172 -0.192431 140.104 0.222048L139.939 0.304924H139.857L136.488 1.96284L140.186 3.78651V3.95236V61.0675L187.762 99.0338L252.43 66.2071C251.444 64.3005 249.965 62.5597 248.322 61.6478Z"
                                    fill="#553DB4" />
                                <path
                                    d="M250.825 62.8027L240.354 67.3244L248.228 73.6723L254.846 70.8027C254.26 67.7592 252.752 64.8027 250.825 62.8027Z"
                                    fill="#274F89" />
                                <path
                                    d="M245.271 164.742L140.027 104.447C136.72 102.56 134.074 97.5556 134.074 93.2898V7.48104C134.074 3.21521 136.72 1.24643 140.027 3.13324L245.271 63.429C248.578 65.3158 251.224 70.3199 251.224 74.5858V160.394C251.224 164.66 248.578 166.629 245.271 164.742Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M242.169 158.765L144.335 102.263C141.29 100.534 138.904 95.9212 138.904 91.9677V13.1455C138.904 9.19204 141.373 7.38001 144.335 9.10965L242.169 65.6113C245.214 67.3409 247.6 71.9533 247.6 75.9067V154.729C247.6 158.682 245.132 160.494 242.169 158.765Z"
                                    fill="white" />
                                <path
                                    d="M242.169 158.765L144.335 102.263C141.29 100.534 138.904 95.9212 138.904 91.9677V13.1455C138.904 9.19204 141.373 7.38001 144.335 9.10965L242.169 65.6113C245.214 67.3409 247.6 71.9533 247.6 75.9067V154.729C247.6 158.682 245.132 160.494 242.169 158.765Z"
                                    fill="white" />
                                <path
                                    d="M246.309 167.931L232.224 166.036L141.209 113.442C138.327 111.794 133.22 111.959 129.843 113.937L65.3502 151.197L56.7842 150.043V154.907C56.7018 156.061 57.3607 157.133 58.7609 157.875L161.966 217.474C164.848 219.123 169.955 218.958 173.332 216.98L243.59 176.422C245.485 175.351 246.391 173.949 246.391 172.63V167.931H246.309Z"
                                    fill="#553DB4" />
                                <path
                                    d="M178.758 134.165L172.212 130.435H100.701L65.402 150.828L56.7842 149.667V154.559C56.7014 155.719 57.3643 156.797 58.773 157.543L162.6 217.48C164.174 218.392 166.329 218.724 168.483 218.558L178.758 134.165Z"
                                    fill="#553DB4" />
                                <path
                                    d="M60.4005 150.295L56.7839 149.758V155.036C56.7053 156.288 57.3343 157.451 58.6708 158.257L60.4005 159.42V150.295Z"
                                    fill="#553DB4" />
                                <path
                                    d="M163.059 217.938C165.866 219.005 169.83 218.759 172.72 217.363V206.522H163.059V217.938Z"
                                    fill="#274F89" />
                                <path
                                    d="M161.965 212.607L58.7334 152.803C55.8498 151.149 56.1793 148.171 59.5572 146.186L129.834 105.489C133.212 103.504 138.32 103.339 141.203 104.993L244.435 164.797C247.318 166.451 246.989 169.429 243.611 171.414L173.334 212.111C169.957 214.096 164.849 214.344 161.965 212.607Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M243.617 170.123L173.344 210.362C169.967 212.325 164.859 212.489 161.976 210.853L58.7503 151.721C57.7617 151.148 57.185 150.494 56.9379 149.758C56.4436 151.148 57.1026 152.539 58.7503 153.52L161.976 212.652C164.859 214.288 169.967 214.124 173.344 212.161L243.617 171.922C245.923 170.614 246.747 168.896 246.253 167.342C245.923 168.324 245.017 169.305 243.617 170.123Z"
                                    fill="white" />
                                <path
                                    d="M102.71 133.941L98.1072 131.154C97.7035 130.9 97.7843 130.562 98.188 130.309L103.598 127.015C104.002 126.761 104.648 126.761 105.051 126.93L109.654 129.718C110.057 129.971 109.977 130.309 109.573 130.562L104.163 133.857C103.759 134.11 103.113 134.11 102.71 133.941Z"
                                    fill="#553DB4" />
                                <path
                                    d="M109.956 137.529L105.353 134.755C104.95 134.503 105.03 134.166 105.434 133.914L110.844 130.636C111.248 130.384 111.894 130.384 112.297 130.552L116.9 133.326C117.304 133.578 117.223 133.914 116.819 134.166L111.409 137.445C110.925 137.697 110.279 137.781 109.956 137.529Z"
                                    fill="#553DB4" />
                                <path
                                    d="M117.202 141.207L112.599 138.884C112.196 138.673 112.276 138.392 112.68 138.18L118.09 135.435C118.494 135.224 119.14 135.224 119.543 135.365L124.146 137.688C124.55 137.899 124.469 138.18 124.065 138.392L118.655 141.137C118.251 141.348 117.606 141.348 117.202 141.207Z"
                                    fill="#553DB4" />
                                <path
                                    d="M127.25 138.723L122.283 135.704C121.847 135.43 121.934 135.064 122.37 134.789L127.25 131.862C127.686 131.587 128.383 131.587 128.819 131.77L133.787 134.789C134.223 135.064 134.136 135.43 133.7 135.704L128.819 138.632C128.383 138.906 127.686 138.998 127.25 138.723Z"
                                    fill="#553DB4" />
                                <path
                                    d="M134.497 142.405L129.529 139.876C129.093 139.646 129.18 139.34 129.616 139.11L134.497 136.657C134.932 136.427 135.629 136.427 136.065 136.58L141.033 139.11C141.469 139.34 141.382 139.646 140.946 139.876L136.065 142.329C135.542 142.559 134.845 142.559 134.497 142.405Z"
                                    fill="#553DB4" />
                                <path
                                    d="M141.743 145.997L136.775 143.481C136.339 143.253 136.426 142.948 136.862 142.719L141.743 140.279C142.178 140.05 142.876 140.05 143.311 140.203L148.279 142.719C148.715 142.948 148.628 143.253 148.192 143.481L143.311 145.921C142.876 146.15 142.178 146.226 141.743 145.997Z"
                                    fill="#553DB4" />
                                <path
                                    d="M148.989 150.86L144.021 148.33C143.585 148.1 143.672 147.794 144.108 147.564L148.989 145.111C149.424 144.881 150.122 144.881 150.557 145.035L155.525 147.564C155.961 147.794 155.874 148.1 155.438 148.33L150.557 150.783C150.034 151.013 149.337 151.013 148.989 150.86Z"
                                    fill="#553DB4" />
                                <path
                                    d="M156.235 154.45L151.267 151.934C150.831 151.706 150.918 151.401 151.354 151.172L156.235 148.732C156.67 148.504 157.368 148.504 157.804 148.656L162.771 151.172C163.207 151.401 163.12 151.706 162.684 151.934L157.804 154.374C157.368 154.603 156.67 154.679 156.235 154.45Z"
                                    fill="#553DB4" />
                                <path
                                    d="M163.481 159.292L158.513 156.257C158.077 155.981 158.164 155.613 158.6 155.337L163.481 152.394C163.917 152.118 164.614 152.118 165.05 152.302L170.017 155.337C170.453 155.613 170.366 155.981 169.93 156.257L165.05 159.2C164.527 159.476 163.829 159.476 163.481 159.292Z"
                                    fill="#553DB4" />
                                <path
                                    d="M170.729 162.906L165.761 160.39C165.325 160.162 165.412 159.857 165.848 159.628L170.729 157.188C171.165 156.96 171.862 156.96 172.298 157.112L177.265 159.628C177.701 159.857 177.614 160.162 177.178 160.39L172.298 162.83C171.862 163.059 171.165 163.135 170.729 162.906Z"
                                    fill="#553DB4" />
                                <path
                                    d="M177.975 167.745L173.007 164.71C172.571 164.434 172.659 164.066 173.094 163.79L177.975 160.847C178.411 160.571 179.108 160.571 179.544 160.755L184.512 163.79C184.947 164.066 184.86 164.434 184.424 164.71L179.544 167.653C179.021 167.929 178.324 167.929 177.975 167.745Z"
                                    fill="#553DB4" />
                                <path
                                    d="M185.221 171.336L180.253 168.831C179.818 168.603 179.905 168.299 180.34 168.071L185.221 165.642C185.657 165.414 186.354 165.414 186.79 165.566L191.758 168.071C192.193 168.299 192.106 168.603 191.67 168.831L186.79 171.26C186.354 171.564 185.657 171.564 185.221 171.336Z"
                                    fill="#553DB4" />
                                <path
                                    d="M192.467 176.2L187.499 173.165C187.064 172.889 187.151 172.521 187.587 172.245L192.467 169.302C192.903 169.026 193.6 169.026 194.036 169.21L199.004 172.245C199.439 172.521 199.352 172.889 198.916 173.165L194.036 176.108C193.513 176.384 192.816 176.384 192.467 176.2Z"
                                    fill="#553DB4" />
                                <path
                                    d="M138.12 136.335L133.152 133.819C132.716 133.591 132.803 133.286 133.239 133.057L138.12 130.617C138.555 130.388 139.252 130.388 139.688 130.541L144.656 133.057C145.092 133.286 145.005 133.591 144.569 133.819L139.688 136.259C139.252 136.488 138.555 136.564 138.12 136.335Z"
                                    fill="#553DB4" />
                                <path
                                    d="M130.871 131.536L125.904 129.007C125.468 128.777 125.555 128.471 125.991 128.241L130.871 125.788C131.307 125.558 132.004 125.558 132.44 125.711L137.408 128.241C137.844 128.471 137.757 128.777 137.321 129.007L132.44 131.46C132.004 131.69 131.307 131.69 130.871 131.536Z"
                                    fill="#553DB4" />
                                <path
                                    d="M123.204 127.841L113.795 122.037C113.409 121.788 113.486 121.457 113.872 121.208L118.191 118.555C118.576 118.306 119.193 118.306 119.579 118.472L128.988 124.276C129.374 124.525 129.296 124.856 128.911 125.105L124.592 127.758C124.206 128.09 123.589 128.09 123.204 127.841Z"
                                    fill="#553DB4" />
                                <path
                                    d="M128.474 123.04L119.866 118.371C119.431 118.125 119.518 117.798 119.953 117.552L124.822 114.931C125.257 114.685 125.952 114.685 126.387 114.849L134.995 119.518C135.429 119.764 135.343 120.091 134.908 120.337L130.039 122.958C129.517 123.204 128.821 123.286 128.474 123.04Z"
                                    fill="#553DB4" />
                                <path
                                    d="M145.366 139.99L140.398 137.461C139.962 137.231 140.049 136.925 140.485 136.695L145.366 134.242C145.801 134.012 146.499 134.012 146.934 134.165L151.902 136.695C152.338 136.925 152.251 137.231 151.815 137.461L146.934 139.914C146.411 140.144 145.714 140.144 145.366 139.99Z"
                                    fill="#553DB4" />
                                <path
                                    d="M152.612 144.761L147.644 141.742C147.208 141.468 147.295 141.102 147.731 140.827L152.612 137.9C153.047 137.625 153.745 137.625 154.18 137.808L159.148 140.827C159.584 141.102 159.497 141.468 159.061 141.742L154.18 144.67C153.745 144.944 153.047 145.036 152.612 144.761Z"
                                    fill="#553DB4" />
                                <path
                                    d="M159.858 148.444L154.89 145.914C154.454 145.684 154.541 145.378 154.977 145.148L159.858 142.695C160.294 142.465 160.991 142.465 161.427 142.619L166.394 145.148C166.83 145.378 166.743 145.684 166.307 145.914L161.427 148.367C160.904 148.597 160.206 148.597 159.858 148.444Z"
                                    fill="#553DB4" />
                                <path
                                    d="M167.106 153.216L162.138 150.197C161.702 149.923 161.789 149.557 162.225 149.282L167.106 146.355C167.542 146.08 168.239 146.08 168.675 146.263L173.642 149.282C174.078 149.557 173.991 149.923 173.555 150.197L168.675 153.125C168.239 153.399 167.542 153.491 167.106 153.216Z"
                                    fill="#553DB4" />
                                <path
                                    d="M174.352 156.898L169.384 154.368C168.948 154.138 169.036 153.832 169.471 153.602L174.352 151.149C174.788 150.919 175.485 150.919 175.921 151.073L180.888 153.602C181.324 153.832 181.237 154.138 180.801 154.368L175.921 156.821C175.398 157.051 174.701 157.051 174.352 156.898Z"
                                    fill="#553DB4" />
                                <path
                                    d="M181.073 161.64L176.602 158.634C176.209 158.36 176.288 157.996 176.68 157.723L181.073 154.807C181.465 154.534 182.092 154.534 182.484 154.716L186.955 157.723C187.348 157.996 187.269 158.36 186.877 158.634L182.484 161.549C182.092 161.914 181.465 161.914 181.073 161.64Z"
                                    fill="#553DB4" />
                                <path
                                    d="M188.319 165.353L183.848 162.823C183.456 162.594 183.534 162.287 183.926 162.057L188.319 159.604C188.711 159.374 189.338 159.374 189.731 159.528L194.202 162.057C194.594 162.287 194.515 162.594 194.123 162.823L189.731 165.276C189.26 165.506 188.632 165.506 188.319 165.353Z"
                                    fill="#553DB4" />
                                <path
                                    d="M195.565 170.095L191.094 167.089C190.702 166.815 190.78 166.451 191.172 166.178L195.565 163.262C195.957 162.989 196.584 162.989 196.977 163.171L201.448 166.178C201.84 166.451 201.761 166.815 201.369 167.089L196.977 170.004C196.584 170.369 195.957 170.369 195.565 170.095Z"
                                    fill="#553DB4" />
                                <path
                                    d="M202.811 173.806L198.34 171.277C197.948 171.047 198.026 170.74 198.418 170.51L202.811 168.057C203.203 167.828 203.831 167.828 204.223 167.981L208.694 170.51C209.086 170.74 209.008 171.047 208.615 171.277L204.223 173.729C203.752 173.959 203.125 173.959 202.811 173.806Z"
                                    fill="#553DB4" />
                                <path
                                    d="M215.799 181.045L205.6 175.171C205.189 174.923 205.271 174.592 205.683 174.343L210.289 171.696C210.7 171.448 211.358 171.448 211.769 171.613L221.968 177.487C222.379 177.735 222.297 178.066 221.886 178.315L217.28 180.962C216.786 181.21 216.128 181.21 215.799 181.045Z"
                                    fill="#553DB4" />
                                <path
                                    d="M230.106 172.536L221.307 167.775C220.884 167.537 220.969 167.219 221.392 166.981L226.13 164.442C226.553 164.204 227.23 164.204 227.653 164.363L236.452 169.124C236.875 169.362 236.79 169.68 236.367 169.918L231.629 172.457C231.122 172.774 230.445 172.774 230.106 172.536Z"
                                    fill="#553DB4" />
                                <path
                                    d="M158.125 139.969L153.654 136.934C153.262 136.658 153.341 136.29 153.733 136.014L158.125 133.071C158.517 132.795 159.145 132.795 159.537 132.979L164.008 136.014C164.4 136.29 164.322 136.658 163.93 136.934L159.537 139.877C159.067 140.153 158.517 140.153 158.125 139.969Z"
                                    fill="#553DB4" />
                                <path
                                    d="M150.198 135.128L145.23 132.612C144.794 132.383 144.881 132.079 145.317 131.85L150.198 129.41C150.633 129.181 151.331 129.181 151.766 129.334L156.734 131.85C157.17 132.079 157.083 132.383 156.647 132.612L151.766 135.052C151.331 135.281 150.633 135.357 150.198 135.128Z"
                                    fill="#553DB4" />
                                <path
                                    d="M142.95 131.476L137.982 128.457C137.546 128.183 137.633 127.817 138.069 127.542L142.95 124.615C143.385 124.34 144.083 124.34 144.518 124.523L149.486 127.542C149.922 127.817 149.835 128.183 149.399 128.457L144.518 131.385C143.995 131.659 143.298 131.751 142.95 131.476Z"
                                    fill="#553DB4" />
                                <path
                                    d="M135.703 126.649L130.736 124.143C130.3 123.915 130.387 123.612 130.823 123.384L135.703 120.954C136.139 120.726 136.837 120.726 137.272 120.878L142.24 123.384C142.676 123.612 142.589 123.915 142.153 124.143L137.272 126.573C136.749 126.877 136.052 126.877 135.703 126.649Z"
                                    fill="#553DB4" />
                                <path
                                    d="M165.371 143.557L160.9 141.051C160.508 140.824 160.587 140.52 160.979 140.292L165.371 137.862C165.764 137.635 166.391 137.635 166.783 137.786L171.254 140.292C171.647 140.52 171.568 140.824 171.176 141.051L166.783 143.481C166.313 143.785 165.685 143.785 165.371 143.557Z"
                                    fill="#553DB4" />
                                <path
                                    d="M172.617 148.423L168.147 145.388C167.754 145.112 167.833 144.744 168.225 144.468L172.617 141.525C173.01 141.249 173.637 141.249 174.029 141.433L178.5 144.468C178.893 144.744 178.814 145.112 178.422 145.388L174.029 148.331C173.637 148.607 173.01 148.607 172.617 148.423Z"
                                    fill="#553DB4" />
                                <path
                                    d="M179.866 152.012L175.395 149.506C175.002 149.279 175.081 148.975 175.473 148.747L179.866 146.317C180.258 146.09 180.885 146.09 181.277 146.242L185.748 148.747C186.141 148.975 186.062 149.279 185.67 149.506L181.277 151.936C180.807 152.24 180.179 152.24 179.866 152.012Z"
                                    fill="#553DB4" />
                                <path
                                    d="M186.428 155.69L181.46 153.16C181.025 152.93 181.112 152.624 181.547 152.394L186.428 149.941C186.864 149.711 187.561 149.711 187.997 149.865L192.965 152.394C193.4 152.624 193.313 152.93 192.877 153.16L187.997 155.613C187.561 155.843 186.864 155.843 186.428 155.69Z"
                                    fill="#553DB4" />
                                <path
                                    d="M194.356 160.465L189.885 157.96C189.493 157.732 189.571 157.428 189.963 157.2L194.356 154.771C194.748 154.543 195.375 154.543 195.768 154.695L200.239 157.2C200.631 157.428 200.552 157.732 200.16 157.96L195.768 160.389C195.297 160.693 194.67 160.693 194.356 160.465Z"
                                    fill="#553DB4" />
                                <path
                                    d="M200.922 164.112L195.954 161.597C195.519 161.368 195.606 161.063 196.042 160.834L200.922 158.394C201.358 158.166 202.055 158.166 202.491 158.318L207.459 160.834C207.895 161.063 207.807 161.368 207.372 161.597L202.491 164.036C202.055 164.265 201.358 164.341 200.922 164.112Z"
                                    fill="#553DB4" />
                                <path
                                    d="M208.168 168.887L203.201 165.881C202.765 165.607 202.852 165.243 203.288 164.97L208.168 162.054C208.604 161.781 209.301 161.781 209.737 161.963L214.705 164.97C215.141 165.243 215.053 165.607 214.618 165.881L209.737 168.796C209.214 169.161 208.517 169.161 208.168 168.887Z"
                                    fill="#553DB4" />
                                <path
                                    d="M215.414 172.568L210.447 170.052C210.011 169.823 210.098 169.518 210.534 169.289L215.414 166.849C215.85 166.621 216.547 166.621 216.983 166.773L221.951 169.289C222.387 169.518 222.3 169.823 221.864 170.052L216.983 172.491C216.547 172.72 215.85 172.796 215.414 172.568Z"
                                    fill="#553DB4" />
                                <path
                                    d="M222.661 177.342L217.693 174.335C217.257 174.062 217.344 173.697 217.78 173.424L222.661 170.508C223.096 170.235 223.794 170.235 224.229 170.417L229.197 173.424C229.633 173.697 229.546 174.062 229.11 174.335L224.229 177.25C223.706 177.615 223.009 177.615 222.661 177.342Z"
                                    fill="#553DB4" />
                                <path
                                    d="M153.819 129.121L148.851 126.592C148.415 126.362 148.502 126.055 148.938 125.826L153.819 123.373C154.254 123.143 154.952 123.143 155.387 123.296L160.355 125.826C160.791 126.055 160.704 126.362 160.268 126.592L155.387 129.045C154.865 129.275 154.167 129.275 153.819 129.121Z"
                                    fill="#553DB4" />
                                <path
                                    d="M147.256 125.408L142.785 122.401C142.393 122.128 142.471 121.764 142.864 121.49L147.256 118.575C147.648 118.301 148.276 118.301 148.668 118.483L153.139 121.49C153.531 121.764 153.453 122.128 153.061 122.401L148.668 125.317C148.197 125.681 147.57 125.681 147.256 125.408Z"
                                    fill="#553DB4" />
                                <path
                                    d="M140.01 120.635L135.539 118.119C135.147 117.89 135.225 117.585 135.618 117.357L140.01 114.917C140.402 114.688 141.03 114.688 141.422 114.841L145.893 117.357C146.285 117.585 146.207 117.89 145.815 118.119L141.422 120.559C140.951 120.787 140.324 120.864 140.01 120.635Z"
                                    fill="#553DB4" />
                                <path
                                    d="M132.764 117.021L128.293 113.986C127.901 113.711 127.979 113.343 128.371 113.067L132.764 110.123C133.156 109.848 133.784 109.848 134.176 110.032L138.647 113.067C139.039 113.343 138.961 113.711 138.568 113.986L134.176 116.93C133.705 117.205 133.078 117.205 132.764 117.021Z"
                                    fill="#553DB4" />
                                <path
                                    d="M140.299 112.168L135.555 109.429C135.139 109.18 135.222 108.848 135.638 108.599L137.219 107.686C137.636 107.437 138.302 107.437 138.718 107.603L143.462 110.342C143.878 110.591 143.795 110.923 143.379 111.172L141.797 112.085C141.381 112.334 140.715 112.417 140.299 112.168Z"
                                    fill="#553DB4" />
                                <path
                                    d="M149.244 118.241L145.178 115.482C144.821 115.231 144.893 114.897 145.249 114.646L146.605 113.726C146.961 113.476 147.532 113.476 147.889 113.643L151.955 116.402C152.312 116.652 152.241 116.987 151.884 117.238L150.528 118.157C150.1 118.408 149.53 118.408 149.244 118.241Z"
                                    fill="#553DB4" />
                                <path
                                    d="M155.998 121.864L151.254 119.105C150.838 118.854 150.921 118.52 151.337 118.269L152.919 117.35C153.335 117.099 154.001 117.099 154.417 117.266L159.161 120.025C159.577 120.275 159.494 120.61 159.078 120.861L157.496 121.78C156.997 122.031 156.331 122.031 155.998 121.864Z"
                                    fill="#553DB4" />
                                <path
                                    d="M163.244 126.661L158.5 123.922C158.084 123.673 158.167 123.341 158.583 123.092L160.165 122.179C160.581 121.93 161.247 121.93 161.663 122.096L166.407 124.835C166.823 125.084 166.74 125.416 166.324 125.665L164.742 126.578C164.326 126.827 163.661 126.91 163.244 126.661Z"
                                    fill="#553DB4" />
                                <path
                                    d="M170.492 130.285L165.748 127.546C165.332 127.297 165.415 126.965 165.831 126.716L167.413 125.803C167.829 125.554 168.495 125.554 168.911 125.72L173.655 128.459C174.071 128.708 173.988 129.04 173.572 129.289L171.991 130.202C171.574 130.451 170.908 130.534 170.492 130.285Z"
                                    fill="#553DB4" />
                                <path
                                    d="M210.348 153.264L205.604 150.506C205.188 150.255 205.271 149.92 205.687 149.67L207.268 148.75C207.684 148.499 208.35 148.499 208.766 148.666L213.511 151.425C213.927 151.676 213.843 152.01 213.427 152.261L211.846 153.181C211.43 153.431 210.764 153.431 210.348 153.264Z"
                                    fill="#553DB4" />
                                <path
                                    d="M217.594 158.096L212.85 155.338C212.434 155.087 212.517 154.752 212.933 154.502L214.514 153.582C214.931 153.331 215.596 153.331 216.013 153.498L220.757 156.257C221.173 156.508 221.09 156.842 220.673 157.093L219.092 158.013C218.593 158.263 217.927 158.263 217.594 158.096Z"
                                    fill="#553DB4" />
                                <path
                                    d="M224.838 161.72L220.094 158.962C219.678 158.711 219.761 158.376 220.177 158.126L221.758 157.206C222.175 156.955 222.841 156.955 223.257 157.122L228.001 159.881C228.417 160.132 228.334 160.466 227.918 160.717L226.336 161.637C225.837 161.887 225.171 161.887 224.838 161.72Z"
                                    fill="#553DB4" />
                                <path
                                    d="M232.086 165.341L227.342 162.583C226.926 162.332 227.009 161.998 227.425 161.747L229.007 160.827C229.423 160.576 230.089 160.576 230.505 160.743L235.249 163.502C235.665 163.753 235.582 164.087 235.166 164.338L233.584 165.258C233.168 165.509 232.502 165.509 232.086 165.341Z"
                                    fill="#553DB4" />
                                <path
                                    d="M161.067 133.864L156.099 130.857C155.663 130.584 155.75 130.22 156.186 129.946L161.067 127.031C161.503 126.757 162.2 126.757 162.636 126.939L167.603 129.946C168.039 130.22 167.952 130.584 167.516 130.857L162.636 133.773C162.2 134.137 161.503 134.137 161.067 133.864Z"
                                    fill="#553DB4" />
                                <path
                                    d="M168.313 137.574L163.345 135.045C162.909 134.815 162.997 134.509 163.432 134.279L168.313 131.826C168.749 131.596 169.446 131.596 169.882 131.749L174.849 134.279C175.285 134.509 175.198 134.815 174.762 135.045L169.882 137.498C169.359 137.728 168.661 137.728 168.313 137.574Z"
                                    fill="#553DB4" />
                                <path
                                    d="M175.559 142.318L170.591 139.311C170.155 139.038 170.243 138.674 170.678 138.4L175.559 135.485C175.995 135.211 176.692 135.211 177.128 135.394L182.095 138.4C182.531 138.674 182.444 139.038 182.008 139.311L177.128 142.227C176.692 142.591 175.995 142.591 175.559 142.318Z"
                                    fill="#553DB4" />
                                <path
                                    d="M182.805 146.028L177.837 143.499C177.401 143.269 177.489 142.963 177.924 142.733L182.805 140.28C183.241 140.05 183.938 140.05 184.374 140.204L189.342 142.733C189.777 142.963 189.69 143.269 189.254 143.499L184.374 145.952C183.851 146.182 183.241 146.182 182.805 146.028Z"
                                    fill="#553DB4" />
                                <path
                                    d="M190.051 149.596L185.083 147.09C184.648 146.863 184.735 146.559 185.171 146.331L190.051 143.901C190.487 143.674 191.184 143.674 191.62 143.826L196.588 146.331C197.023 146.559 196.936 146.863 196.501 147.09L191.62 149.52C191.097 149.824 190.4 149.824 190.051 149.596Z"
                                    fill="#553DB4" />
                                <path
                                    d="M197.297 154.424L192.329 151.405C191.894 151.131 191.981 150.765 192.417 150.49L197.297 147.563C197.733 147.288 198.43 147.288 198.866 147.471L203.834 150.49C204.27 150.765 204.182 151.131 203.747 151.405L198.866 154.333C198.43 154.607 197.733 154.699 197.297 154.424Z"
                                    fill="#553DB4" />
                                <path
                                    d="M204.545 158.05L199.578 155.545C199.142 155.317 199.229 155.013 199.665 154.785L204.545 152.356C204.981 152.128 205.678 152.128 206.114 152.28L211.082 154.785C211.518 155.013 211.43 155.317 210.995 155.545L206.114 157.974C205.591 158.278 204.894 158.278 204.545 158.05Z"
                                    fill="#553DB4" />
                                <path
                                    d="M211.791 162.878L206.824 159.858C206.388 159.584 206.475 159.218 206.911 158.944L211.791 156.016C212.227 155.741 212.924 155.741 213.36 155.924L218.328 158.944C218.764 159.218 218.677 159.584 218.241 159.858L213.36 162.786C212.924 163.061 212.227 163.152 211.791 162.878Z"
                                    fill="#553DB4" />
                                <path
                                    d="M219.037 166.503L214.07 163.998C213.634 163.77 213.721 163.466 214.157 163.238L219.037 160.809C219.473 160.581 220.17 160.581 220.606 160.733L225.574 163.238C226.01 163.466 225.923 163.77 225.487 163.998L220.606 166.427C220.083 166.731 219.386 166.731 219.037 166.503Z"
                                    fill="#553DB4" />
                                <path
                                    d="M120.36 133.877L106.566 125.742C106.153 125.488 106.236 125.149 106.649 124.895L111.274 122.183C111.687 121.929 112.348 121.929 112.761 122.099L126.555 130.233C126.968 130.487 126.885 130.826 126.472 131.08L121.847 133.792C121.434 134.131 120.773 134.131 120.36 133.877Z"
                                    fill="#553DB4" />
                                <path
                                    d="M208.526 184.62L194.732 177.299C194.319 177.07 194.402 176.765 194.815 176.536L199.44 174.096C199.853 173.867 200.514 173.867 200.927 174.019L214.721 181.34C215.134 181.569 215.051 181.874 214.638 182.103L210.013 184.543C209.6 184.848 208.939 184.848 208.526 184.62Z"
                                    fill="#553DB4" />
                                <path
                                    d="M179.602 177.788L175 175C174.596 174.747 174.677 174.409 175.081 174.156L180.49 170.861C180.894 170.608 181.54 170.608 181.944 170.777L186.546 173.564C186.95 173.818 186.869 174.156 186.466 174.409L181.056 177.703C180.652 177.957 180.006 177.957 179.602 177.788Z"
                                    fill="#553DB4" />
                                <path
                                    d="M127.644 147.23L119.861 143.035C119.434 142.793 119.519 142.47 119.947 142.228L125.677 139.082C126.105 138.84 126.789 138.84 127.217 139.001L134.999 143.196C135.427 143.438 135.342 143.761 134.914 144.003L129.184 147.15C128.67 147.392 127.986 147.392 127.644 147.23Z"
                                    fill="#553DB4" />
                                <path
                                    d="M172.918 173.769L165.734 169.59C165.339 169.349 165.418 169.027 165.813 168.786L171.103 165.652C171.497 165.411 172.129 165.411 172.524 165.572L179.708 169.751C180.103 169.992 180.024 170.313 179.629 170.554L174.339 173.688C173.866 173.929 173.234 174.01 172.918 173.769Z"
                                    fill="#553DB4" />
                                <path
                                    d="M137.893 153.269L130.709 149.074C130.314 148.832 130.393 148.509 130.788 148.267L136.077 145.121C136.472 144.879 137.103 144.879 137.498 145.04L144.682 149.235C145.077 149.477 144.998 149.8 144.603 150.042L139.314 153.189C138.84 153.431 138.209 153.431 137.893 153.269Z"
                                    fill="#553DB4" />
                                <path
                                    d="M137.535 144.928L169.995 163.663C170.407 163.91 170.407 164.238 169.995 164.485L164.393 167.69C163.981 167.936 163.322 167.936 162.91 167.69L130.449 148.954L137.535 144.928Z"
                                    fill="#553DB4" />
                                <path
                                    d="M198.57 185.087L203.8 187.929C204.114 188.088 204.036 188.405 203.722 188.564L201.605 189.834C201.292 189.993 200.743 190.072 200.43 189.913L195.2 187.071C194.886 186.912 194.964 186.595 195.278 186.436L197.394 185.166C197.786 185.007 198.257 184.928 198.57 185.087Z"
                                    fill="#553DB4" />
                                <path
                                    d="M191.57 181.087L196.8 183.929C197.114 184.088 197.036 184.405 196.722 184.564L194.605 185.834C194.292 185.993 193.743 186.072 193.43 185.913L188.2 183.071C187.886 182.912 187.964 182.595 188.278 182.436L190.394 181.166C190.786 181.007 191.257 180.928 191.57 181.087Z"
                                    fill="#553DB4" />
                                <path
                                    d="M195.641 178.673L200.871 181.516C201.184 181.675 201.106 181.992 200.792 182.151L198.676 183.421C198.362 183.58 197.814 183.659 197.5 183.5L192.27 180.658C191.956 180.499 192.035 180.182 192.348 180.023L194.465 178.753C194.857 178.594 195.327 178.515 195.641 178.673Z"
                                    fill="#553DB4" />
                                <path
                                    d="M184.57 177.087L189.8 179.929C190.114 180.088 190.036 180.405 189.722 180.564L187.605 181.834C187.292 181.993 186.743 182.072 186.43 181.913L181.2 179.071C180.886 178.912 180.964 178.595 181.278 178.436L183.394 177.166C183.786 177.007 184.257 176.928 184.57 177.087Z"
                                    fill="#553DB4" />
                                <path opacity="0.42"
                                    d="M178.491 163.596L205.66 146.888L144.548 111.59L115.723 130.932L178.491 163.596Z"
                                    fill="url(#paint0_radial)" />
                                <path opacity="0.42"
                                    d="M205.661 146.89L211.908 107.528L154.559 74.6387V117.237L205.661 146.89Z"
                                    fill="url(#paint1_radial)" />
                                <path
                                    d="M148.539 55.5195L154.635 52.0574C154.71 51.9822 154.786 51.9822 154.936 52.0574L230.5 96.001C230.726 96.1515 231 96.1999 231 96.501L223.5 100.501C223 99.501 222.075 99.501 222 99.501L225.155 114.976L225.08 108.052L154.936 67.5613C154.711 67.4108 154.485 67.0345 154.485 66.7334V61.7662L148.539 55.5195Z"
                                    fill="#E2DCFF" />
                                <path d="M231 96.501L224.5 100.001L225 115.001L231 111.501V96.501Z" fill="#AC9BFF" />
                                <path
                                    d="M148.764 71.0988L224.778 114.976C225.004 115.127 225.23 114.976 225.23 114.6V100.225C225.23 99.9239 225.004 99.4724 224.778 99.3971L148.764 55.5196C148.538 55.3691 148.312 55.5196 148.312 55.8959V70.2709C148.312 70.5719 148.538 70.9482 148.764 71.0988Z"
                                    fill="#9080D3" />
                                <path opacity="0.6"
                                    d="M159.3 68.1631L218.531 103.009C216.724 104.59 214.993 106.396 213.413 108.353L153.73 73.883C155.386 71.7756 157.268 69.8188 159.3 68.1631Z"
                                    fill="#553DB4" />
                                <path
                                    d="M150.946 66.8095L222.67 108.203C222.82 108.279 222.971 108.203 222.971 107.978V104.365C222.971 104.139 222.82 103.913 222.67 103.763L150.946 62.369C150.795 62.2938 150.645 62.369 150.645 62.5948V66.2074C150.645 66.4332 150.795 66.659 150.946 66.8095Z"
                                    fill="#553DB4" />
                                <path
                                    d="M150.947 62.369L152.377 63.197V65.1537C152.377 65.3795 152.527 65.6053 152.678 65.7558L222.897 106.322V107.978C222.897 108.203 222.746 108.279 222.596 108.203L150.871 66.8095C150.721 66.7342 150.57 66.4332 150.57 66.2074V62.5948C150.646 62.369 150.796 62.2938 150.947 62.369Z"
                                    fill="#553DB4" />
                                <path opacity="0.96"
                                    d="M110.682 222.075L51 187.605C58.6767 183.165 65.1492 173.456 67.1813 164.124C68.0091 160.361 70.267 157.049 73.6538 155.167L118.735 129.127C131.304 121.902 139.432 108.806 140.26 94.3561C141.013 82.1637 150.195 70.1218 157.42 65.9824L217.102 100.452C210.028 104.516 202.276 115.956 201.373 127.923C200.319 142.373 192.417 155.469 179.848 162.769L133.336 189.637C130.025 191.594 127.692 194.83 126.864 198.593C124.832 207.926 118.359 217.635 110.682 222.075Z"
                                    fill="url(#paint2_linear)" />
                                <path opacity="0.8"
                                    d="M157.421 66.0566L217.104 100.526C216.652 100.828 216.125 101.129 215.674 101.43L156.066 66.9598C156.443 66.6587 156.894 66.3577 157.421 66.0566Z"
                                    fill="#553DB4" />
                                <path
                                    d="M100.295 95.7861H119.336V136.427C119.336 137.857 118.433 139.212 116.551 140.341C112.864 142.524 106.843 142.524 103.08 140.341C101.198 139.287 100.295 137.857 100.295 136.427V95.7861Z"
                                    fill="url(#paint3_linear)" />
                                <path
                                    d="M100.295 95.7861H119.336V136.427C119.336 137.857 118.433 139.212 116.551 140.341C112.864 142.524 106.843 142.524 103.08 140.341C101.198 139.287 100.295 137.857 100.295 136.427V95.7861Z"
                                    fill="url(#paint4_linear)" />
                                <path
                                    d="M109.778 101.28C115.015 101.28 119.261 98.8204 119.261 95.7861C119.261 92.7518 115.015 90.292 109.778 90.292C104.541 90.292 100.295 92.7518 100.295 95.7861C100.295 98.8204 104.541 101.28 109.778 101.28Z"
                                    fill="#B4AFE5" />
                                <path opacity="0.5"
                                    d="M103.08 99.2474C106.768 101.43 112.789 101.43 116.552 99.2474C118.283 98.1937 119.261 96.9143 119.337 95.5596C119.412 97.0648 118.509 98.4947 116.552 99.6237C112.864 101.806 106.843 101.806 103.08 99.6237C101.123 98.4947 100.22 96.9895 100.295 95.5596C100.371 96.9143 101.274 98.269 103.08 99.2474Z"
                                    fill="white" />
                                <path
                                    d="M100.295 86.9814H119.336V90.6693C119.336 92.0993 118.433 93.4539 116.551 94.5829C112.864 96.7654 106.843 96.7654 103.08 94.5829C101.198 93.5292 100.295 92.0993 100.295 90.6693V86.9814Z"
                                    fill="url(#paint5_linear)" />
                                <path
                                    d="M100.295 86.9814H119.336V90.6693C119.336 92.0993 118.433 93.4539 116.551 94.5829C112.864 96.7654 106.843 96.7654 103.08 94.5829C101.198 93.5292 100.295 92.0993 100.295 90.6693V86.9814Z"
                                    fill="url(#paint6_linear)" />
                                <path
                                    d="M103.08 90.8947C106.768 93.0773 112.789 93.0773 116.552 90.8947C120.24 88.7122 120.24 85.2501 116.552 83.1428C112.864 80.9602 106.843 80.9602 103.08 83.1428C99.317 85.2501 99.317 88.7122 103.08 90.8947Z"
                                    fill="#B4AFE5" />
                                <path opacity="0.5"
                                    d="M103.08 90.4417C106.768 92.6243 112.789 92.6243 116.552 90.4417C118.283 89.388 119.261 88.1086 119.337 86.7539C119.412 88.2591 118.509 89.6891 116.552 90.818C112.864 93.0006 106.843 93.0006 103.08 90.818C101.123 89.6891 100.22 88.1839 100.295 86.7539C100.371 88.1086 101.274 89.4633 103.08 90.4417Z"
                                    fill="white" />
                                <path
                                    d="M116.027 118.439H135.069V145.534C135.069 146.964 134.165 148.318 132.284 149.447C128.596 151.63 122.575 151.63 118.812 149.447C116.93 148.394 116.027 146.964 116.027 145.534V118.439Z"
                                    fill="#C0D6FF" />
                                <path
                                    d="M116.027 118.439H135.069V145.534C135.069 146.964 134.165 148.318 132.284 149.447C128.596 151.63 122.575 151.63 118.812 149.447C116.93 148.394 116.027 146.964 116.027 145.534V118.439Z"
                                    fill="url(#paint7_linear)" />
                                <path
                                    d="M118.811 122.279C122.498 124.461 128.519 124.461 132.282 122.279C135.97 120.096 135.97 116.634 132.282 114.527C128.595 112.344 122.574 112.344 118.811 114.527C115.048 116.709 115.048 120.171 118.811 122.279Z"
                                    fill="#B4AFE5" />
                                <path opacity="0.5"
                                    d="M118.811 121.902C122.498 124.084 128.519 124.084 132.282 121.902C134.013 120.848 134.992 119.569 135.067 118.214C135.142 119.719 134.239 121.149 132.282 122.278C128.595 124.461 122.574 124.461 118.811 122.278C116.854 121.149 115.951 119.644 116.026 118.214C116.101 119.569 117.004 120.848 118.811 121.902Z"
                                    fill="white" />
                                <path
                                    d="M131.756 113.623H150.797V154.565C150.797 155.995 149.894 157.35 148.012 158.479C144.325 160.662 138.304 160.662 134.541 158.479C132.659 157.425 131.756 155.995 131.756 154.565V113.623Z"
                                    fill="url(#paint8_linear)" />
                                <path
                                    d="M131.756 113.623H150.797V154.565C150.797 155.995 149.894 157.35 148.012 158.479C144.325 160.662 138.304 160.662 134.541 158.479C132.659 157.425 131.756 155.995 131.756 154.565V113.623Z"
                                    fill="url(#paint9_linear)" />
                                <path
                                    d="M134.539 117.536C138.227 119.719 144.248 119.719 148.011 117.536C151.699 115.354 151.699 111.892 148.011 109.784C144.323 107.602 138.302 107.602 134.539 109.784C130.776 111.892 130.776 115.354 134.539 117.536Z"
                                    fill="#B4AFE5" />
                                <path opacity="0.5"
                                    d="M134.539 117.085C138.227 119.268 144.248 119.268 148.011 117.085C149.742 116.032 150.72 114.752 150.796 113.397C150.871 114.903 149.968 116.333 148.011 117.462C144.323 119.644 138.302 119.644 134.539 117.462C132.582 116.333 131.679 114.827 131.754 113.397C131.83 114.752 132.733 116.107 134.539 117.085Z"
                                    fill="white" />
                                <path
                                    d="M147.485 130.104H166.451V163.671C166.451 165.101 165.548 166.456 163.667 167.585C159.979 169.767 153.958 169.767 150.195 167.585C148.313 166.531 147.41 165.101 147.41 163.671V130.104H147.485Z"
                                    fill="url(#paint10_linear)" />
                                <path
                                    d="M147.485 130.104H166.451V163.671C166.451 165.101 165.548 166.456 163.667 167.585C159.979 169.767 153.958 169.767 150.195 167.585C148.313 166.531 147.41 165.101 147.41 163.671V130.104H147.485Z"
                                    fill="url(#paint11_linear)" />
                                <path
                                    d="M150.27 133.944C153.957 136.126 159.978 136.126 163.741 133.944C167.429 131.761 167.429 128.299 163.741 126.192C160.054 124.009 154.033 124.009 150.27 126.192C146.506 128.374 146.506 131.836 150.27 133.944Z"
                                    fill="#B4AFE5" />
                                <path opacity="0.5"
                                    d="M150.269 133.568C153.957 135.75 159.978 135.75 163.741 133.568C165.472 132.514 166.451 131.235 166.526 129.88C166.601 131.385 165.698 132.815 163.741 133.944C160.054 136.127 154.033 136.127 150.269 133.944C148.313 132.815 147.41 131.31 147.485 129.88C147.56 131.235 148.463 132.514 150.269 133.568Z"
                                    fill="white" />
                                <path
                                    d="M163.215 147.415H182.256V172.703C182.256 174.133 181.353 175.488 179.471 176.617C175.784 178.799 169.763 178.799 166 176.617C164.118 175.563 163.215 174.133 163.215 172.703V147.415Z"
                                    fill="url(#paint12_linear)" />
                                <path
                                    d="M163.215 147.415H182.256V172.703C182.256 174.133 181.353 175.488 179.471 176.617C175.784 178.799 169.763 178.799 166 176.617C164.118 175.563 163.215 174.133 163.215 172.703V147.415Z"
                                    fill="url(#paint13_linear)" />
                                <path
                                    d="M166 151.329C169.688 153.512 175.709 153.512 179.472 151.329C183.16 149.147 183.16 145.685 179.472 143.577C175.784 141.395 169.763 141.395 166 143.577C162.237 145.685 162.237 149.147 166 151.329Z"
                                    fill="#D8D1F9" />
                                <path opacity="0.5"
                                    d="M166 150.877C169.688 153.06 175.709 153.06 179.472 150.877C181.203 149.824 182.181 148.544 182.256 147.189C182.332 148.695 181.429 150.125 179.472 151.254C175.784 153.436 169.763 153.436 166 151.254C164.043 150.125 163.14 148.619 163.215 147.189C163.291 148.544 164.194 149.899 166 150.877Z"
                                    fill="white" />
                                <path opacity="0.2"
                                    d="M114.593 89.1026C111.98 90.3795 107.829 90.3795 105.216 89.1026C102.603 87.8257 102.603 85.675 105.216 84.3981C107.829 83.1212 111.98 83.1212 114.593 84.3981C117.13 85.7423 117.13 87.8257 114.593 89.1026Z"
                                    fill="#242993" />
                                <path opacity="0.2"
                                    d="M145.597 115.582C143.212 116.859 139.424 116.859 137.039 115.582C134.654 114.305 134.654 112.155 137.039 110.878C139.424 109.601 143.212 109.601 145.597 110.878C147.983 112.155 147.983 114.305 145.597 115.582Z"
                                    fill="#242993" />
                                <path opacity="0.2"
                                    d="M177.433 149.625C174.831 151.115 170.698 151.115 168.096 149.625C165.494 148.136 165.494 145.627 168.096 144.137C170.698 142.647 174.831 142.647 177.433 144.137C180.035 145.627 180.035 148.057 177.433 149.625Z"
                                    fill="#242993" />
                                <g class="money1">
                                    <path
                                        d="M166.507 145.763L162.895 143.505L163.421 143.054L164.701 143.355L166.507 145.763Z"
                                        fill="#D78100" />
                                    <path d="M181.785 121.605L178.248 119.347L180.355 123.26L181.785 121.605Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M180.614 137.77C184.864 131.113 183.85 122.87 178.349 119.358C172.848 115.847 164.944 118.396 160.695 125.053C156.445 131.71 157.459 139.953 162.96 143.465C168.46 146.976 176.365 144.427 180.614 137.77Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M184.147 140.064C188.397 133.407 187.383 125.164 181.882 121.652C176.382 118.141 168.478 120.69 164.228 127.347C159.978 134.004 160.992 142.247 166.493 145.759C171.994 149.27 179.898 146.72 184.147 140.064Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M161.691 137.861C162.143 141.172 163.723 144.032 166.508 145.763C172.002 149.3 179.905 146.741 184.195 140.118C186.302 136.807 187.13 133.119 186.754 129.808L161.691 137.861Z"
                                        fill="#D78100" />
                                    <path
                                        d="M183.705 139.779C187.754 133.437 186.775 125.577 181.52 122.222C176.264 118.867 168.722 121.288 164.674 127.629C160.626 133.971 161.604 141.831 166.859 145.186C172.115 148.541 179.657 146.12 183.705 139.779Z"
                                        fill="#FFAD00" />
                                    <path
                                        d="M181.866 138.605C185.132 133.49 184.342 127.149 180.103 124.443C175.864 121.736 169.78 123.689 166.514 128.804C163.249 133.92 164.038 140.26 168.278 142.967C172.517 145.673 178.601 143.72 181.866 138.605Z"
                                        fill="#FFAD00" />
                                    <path
                                        d="M180.129 124.463C179.978 124.388 179.753 124.237 179.602 124.162C183.365 127.022 183.967 133.043 180.882 137.935C177.796 142.827 172.076 144.784 167.861 142.601C168.012 142.752 168.162 142.827 168.388 142.978C172.603 145.687 178.699 143.73 181.935 138.612C185.096 133.495 184.344 127.173 180.129 124.463Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M167.185 129.884C170.421 124.766 176.518 122.809 180.732 125.518C181.56 126.045 182.313 126.798 182.84 127.55C182.237 126.271 181.259 125.217 180.055 124.465C175.84 121.755 169.744 123.712 166.508 128.83C163.874 132.894 163.874 137.711 166.131 140.872C164.551 137.786 164.852 133.571 167.185 129.884Z"
                                        fill="#D78100" />
                                    <path
                                        d="M162.897 143.506C163.725 144.032 164.628 144.409 165.531 144.71C164.854 143.957 163.875 142.828 163.875 142.828L162.521 140.721L162.37 139.592L162.295 137.71L158.08 135.001C158.381 138.538 160.037 141.624 162.897 143.506Z"
                                        fill="#D78100" />
                                    <path
                                        d="M177.975 120.289L176.019 119.009C175.943 118.934 175.943 118.859 175.943 118.783V118.708C176.019 118.633 176.094 118.633 176.169 118.633L178.126 119.912C178.201 119.988 178.201 120.063 178.201 120.138V120.213C178.126 120.289 178.051 120.364 177.975 120.289Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M175.04 120.063L172.632 118.483C172.557 118.407 172.481 118.332 172.557 118.182L172.632 118.106C172.707 118.031 172.782 117.956 172.933 118.031L175.341 119.612C175.417 119.687 175.492 119.762 175.417 119.913L175.341 119.988C175.266 120.063 175.191 120.063 175.04 120.063Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M172.255 120.365L169.847 118.86C169.771 118.784 169.696 118.634 169.771 118.559L169.922 118.408C169.997 118.333 170.148 118.258 170.223 118.333L172.631 119.838C172.707 119.913 172.782 120.064 172.707 120.139L172.556 120.29C172.481 120.365 172.33 120.44 172.255 120.365Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M169.321 121.643L166.687 119.988C166.611 119.912 166.536 119.762 166.611 119.687L166.762 119.536C166.837 119.461 166.988 119.386 167.063 119.461L169.697 121.117C169.772 121.192 169.848 121.342 169.772 121.418L169.622 121.568C169.546 121.719 169.471 121.719 169.321 121.643Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M166.987 123.3L164.202 121.494C164.127 121.418 164.052 121.268 164.127 121.193L164.277 121.042C164.353 120.967 164.503 120.891 164.578 120.967L167.363 122.773C167.438 122.848 167.514 122.999 167.438 123.074L167.288 123.225C167.213 123.3 167.062 123.375 166.987 123.3Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M164.955 125.181L162.245 123.45C162.17 123.374 162.095 123.224 162.17 123.149L162.32 122.998C162.396 122.923 162.546 122.848 162.621 122.923L165.331 124.654C165.406 124.729 165.481 124.88 165.406 124.955L165.256 125.105C165.18 125.181 165.03 125.181 164.955 125.181Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M163.376 127.288L160.591 125.557C160.516 125.482 160.44 125.331 160.516 125.256L160.666 125.105C160.741 125.03 160.892 124.955 160.967 125.03L163.752 126.761C163.827 126.836 163.902 126.987 163.827 127.062L163.677 127.213C163.601 127.288 163.451 127.363 163.376 127.288Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M162.019 129.546L159.31 127.815C159.234 127.74 159.159 127.589 159.234 127.514L159.385 127.363C159.46 127.288 159.611 127.213 159.686 127.288L162.395 129.019C162.471 129.094 162.546 129.245 162.471 129.32L162.32 129.471C162.32 129.621 162.169 129.621 162.019 129.546Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M161.116 132.104L158.481 130.449C158.406 130.373 158.331 130.223 158.406 130.148L158.557 129.997C158.632 129.922 158.782 129.847 158.858 129.922L161.492 131.578C161.567 131.653 161.642 131.803 161.567 131.879L161.417 132.029C161.341 132.18 161.266 132.18 161.116 132.104Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M160.816 135.04L158.106 133.309C158.031 133.234 157.956 133.083 158.031 133.008L158.182 132.857C158.257 132.782 158.407 132.707 158.483 132.782L161.192 134.513C161.267 134.588 161.343 134.739 161.267 134.814L161.117 134.965C161.042 135.115 160.891 135.115 160.816 135.04Z"
                                        fill="#DD821F" />
                                </g>
                                <g class="money2">
                                    <path
                                        d="M135.546 110.725L131.934 108.467L132.46 108.016L133.74 108.317L135.546 110.725Z"
                                        fill="#D78100" />
                                    <path d="M150.748 86.5655L147.211 84.3076L149.318 88.2212L150.748 86.5655Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M149.614 102.77C153.864 96.1129 152.85 87.8698 147.349 84.3582C141.848 80.8466 133.944 83.3964 129.695 90.0532C125.445 96.71 126.459 104.953 131.96 108.465C137.46 111.976 145.365 109.426 149.614 102.77Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M153.188 105.012C157.438 98.355 156.424 90.1119 150.923 86.6004C145.423 83.0888 137.519 85.6386 133.269 92.2954C129.019 98.9522 130.033 107.195 135.534 110.707C141.035 114.218 148.939 111.669 153.188 105.012Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M130.729 102.822C131.18 106.133 132.761 108.993 135.545 110.724C141.039 114.261 148.942 111.702 153.232 105.079C155.339 101.768 156.167 98.0801 155.791 94.7686L130.729 102.822Z"
                                        fill="#D78100" />
                                    <path
                                        d="M152.78 104.779C148.716 111.101 141.19 113.585 135.921 110.198C130.653 106.811 129.675 98.9838 133.739 92.5865C137.803 86.2646 145.329 83.7809 150.597 87.1677C155.866 90.5545 156.844 98.3817 152.78 104.779Z"
                                        fill="#FFAD00" />
                                    <path
                                        d="M150.905 103.549C154.171 98.4341 153.381 92.0935 149.142 89.3871C144.903 86.6808 138.819 88.6335 135.553 93.7488C132.288 98.864 133.077 105.205 137.317 107.911C141.556 110.617 147.64 108.665 150.905 103.549Z"
                                        fill="#FFAD00" />
                                    <path
                                        d="M149.092 89.426C148.941 89.3508 148.716 89.2003 148.565 89.125C152.328 91.9849 152.93 98.0059 149.844 102.898C146.759 107.79 141.039 109.747 136.824 107.564C136.975 107.715 137.125 107.79 137.351 107.94C141.566 110.65 147.662 108.693 150.898 103.575C154.134 98.4574 153.307 92.1354 149.092 89.426Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M136.222 94.8435C139.459 89.7257 145.555 87.7689 149.769 90.4784C150.597 91.0052 151.35 91.7578 151.877 92.5104C151.275 91.231 150.296 90.1773 149.092 89.4247C144.877 86.7153 138.781 88.6721 135.545 93.7898C132.911 97.854 132.911 102.671 135.169 105.832C133.588 102.746 133.889 98.4561 136.222 94.8435Z"
                                        fill="#D78100" />
                                    <path
                                        d="M131.932 108.466C132.76 108.993 133.663 109.37 134.566 109.671C133.889 108.918 132.91 107.789 132.91 107.789L131.556 105.682L131.405 104.553L131.33 102.671L127.115 99.9619C127.416 103.499 129.072 106.585 131.932 108.466Z"
                                        fill="#D78100" />
                                    <path
                                        d="M146.975 85.3637L145.019 84.0843C144.943 84.0091 144.943 83.9338 144.943 83.8585V83.7833C145.019 83.708 145.094 83.708 145.169 83.708L147.126 84.9874C147.201 85.0627 147.201 85.138 147.201 85.2132V85.2885C147.201 85.3637 147.051 85.439 146.975 85.3637Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M144.116 85.0633L141.708 83.4827C141.633 83.4075 141.557 83.3322 141.633 83.1817L141.708 83.1064C141.783 83.0312 141.859 82.9559 142.009 83.0312L144.417 84.6117C144.493 84.6869 144.568 84.7622 144.493 84.9127L144.417 84.988C144.342 85.1385 144.192 85.1385 144.116 85.0633Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M141.331 85.4392L138.923 83.934C138.848 83.8587 138.772 83.7082 138.848 83.6329L138.998 83.4824C139.073 83.4071 139.224 83.3319 139.299 83.4072L141.708 84.9124C141.783 84.9876 141.858 85.1382 141.783 85.2135L141.632 85.3639C141.557 85.4392 141.406 85.5145 141.331 85.4392Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M138.395 86.7187L135.761 85.0629C135.685 84.9876 135.61 84.8371 135.685 84.7618L135.836 84.6113C135.911 84.536 136.062 84.4608 136.137 84.5361L138.771 86.1918C138.846 86.2671 138.922 86.4176 138.846 86.4928L138.696 86.6434C138.621 86.7186 138.47 86.7939 138.395 86.7187Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M135.987 88.2998L133.202 86.4935C133.127 86.4183 133.052 86.2678 133.127 86.1925L133.277 86.042C133.353 85.9667 133.503 85.8915 133.578 85.9667L136.363 87.773C136.438 87.8482 136.514 87.9988 136.438 88.074L136.288 88.2246C136.288 88.3751 136.137 88.3751 135.987 88.2998Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M134.029 90.1806L131.319 88.4496C131.244 88.3744 131.169 88.2238 131.244 88.1486L131.395 87.998C131.47 87.9228 131.62 87.8475 131.696 87.9228L134.405 89.6538C134.48 89.729 134.556 89.8796 134.48 89.9549L134.33 90.1054C134.255 90.2559 134.104 90.2559 134.029 90.1806Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M132.45 92.3642L129.665 90.6332C129.59 90.5579 129.515 90.4074 129.59 90.3321L129.74 90.1816C129.816 90.1064 129.966 90.0311 130.041 90.1064L132.826 91.8374C132.901 91.9126 132.977 92.0631 132.901 92.1384L132.751 92.2889C132.675 92.3642 132.525 92.4395 132.45 92.3642Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M131.095 94.622L128.386 92.891C128.31 92.8157 128.235 92.6652 128.31 92.59L128.461 92.4394C128.536 92.3642 128.687 92.2889 128.762 92.3642L131.471 94.0952C131.547 94.1705 131.622 94.321 131.547 94.3962L131.396 94.5467C131.321 94.6973 131.17 94.6973 131.095 94.622Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M130.192 97.1805L127.558 95.5248C127.482 95.4495 127.407 95.299 127.482 95.2238L127.633 95.0732C127.708 94.998 127.859 94.9227 127.934 94.998L130.568 96.6537C130.643 96.729 130.719 96.8795 130.643 96.9548L130.493 97.1053C130.418 97.2558 130.267 97.2558 130.192 97.1805Z"
                                        fill="#DD821F" />
                                    <path
                                        d="M129.816 100.116L127.106 98.3852C127.031 98.3099 126.956 98.1594 127.031 98.0841L127.182 97.9336C127.257 97.8583 127.407 97.7831 127.483 97.8583L130.192 99.5893C130.267 99.6646 130.343 99.8151 130.267 99.8904L130.117 100.041C130.117 100.191 129.966 100.191 129.816 100.116Z"
                                        fill="#DD821F" />
                                </g>
                                <g class="money3">
                                    <path
                                        d="M103.521 84.8957L99.903 82.6668L100.35 82.2564L101.609 82.4687L103.521 84.8957Z"
                                        fill="#D78100" />
                                    <path
                                        d="M118.378 60.4699L114.804 58.3023C114.804 58.3023 116.905 62.1645 117.01 62.1822C117.054 62.2435 118.378 60.4699 118.378 60.4699Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M117.473 76.7246C113.314 83.4711 105.478 86.09 99.9028 82.6676C94.3273 79.2451 93.179 71.0102 97.3385 64.2637C101.498 57.5172 109.333 54.8982 114.909 58.3207C120.467 61.8481 121.571 70.0217 117.473 76.7246Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M121.056 78.9076C125.209 72.1908 124.077 63.9633 118.527 60.5311C112.976 57.099 105.11 59.7618 100.956 66.4786C96.8027 73.1955 97.9349 81.4229 103.485 84.8551C109.035 88.2873 116.902 85.6245 121.056 78.9076Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M98.5822 77.048C99.0574 80.3122 100.732 83.185 103.52 84.8962C109.095 88.3187 116.93 85.6997 121.09 78.9532C123.161 75.6324 123.885 71.9767 123.471 68.6688L98.5822 77.048Z"
                                        fill="#D78100" />
                                    <path
                                        d="M120.606 78.6347C124.563 72.236 123.472 64.3905 118.169 61.1113C112.866 57.8322 105.36 60.3611 101.403 66.7598C97.4459 73.1585 98.537 81.004 103.84 84.2831C109.143 87.5623 116.649 85.0334 120.606 78.6347Z"
                                        fill="#FFAD00" />
                                    <path
                                        d="M118.75 77.4923C121.942 72.3309 121.061 66.0024 116.784 63.3572C112.506 60.7121 106.451 62.7519 103.26 67.9133C100.068 73.0747 100.948 79.4031 105.226 82.0483C109.503 84.6935 115.558 82.6537 118.75 77.4923Z"
                                        fill="#FFAD00" />
                                    <path
                                        d="M116.798 63.4424C116.606 63.3021 116.457 63.223 116.247 63.1877C120.092 65.9945 120.754 71.9888 117.709 76.9263C114.664 81.8638 109.026 83.9357 104.743 81.8105C104.935 81.9508 105.084 82.0298 105.276 82.1702C109.532 84.7765 115.59 82.7755 118.775 77.6457C121.935 72.3497 121.054 66.0488 116.798 63.4424Z"
                                        fill="#EF8F00" />
                                    <path
                                        d="M103.936 68.9903C107.121 63.8606 113.136 61.7982 117.435 64.4659C118.266 64.9836 119.017 65.6499 119.628 66.5085C119.026 65.2737 118.075 64.1959 116.799 63.4411C112.543 60.8347 106.485 62.8358 103.299 67.9655C100.772 72.0729 100.815 76.9374 103.102 80.0213C101.463 76.9386 101.646 72.6519 103.936 68.9903Z"
                                        fill="#D78100" />
                                    <path
                                        d="M99.9017 82.668C100.732 83.1858 101.641 83.5549 102.524 83.7578C101.896 83.0042 100.839 81.9086 100.839 81.9086L99.4171 79.7799L99.2792 78.6773L99.1601 76.8223L94.9042 74.216C95.2826 77.7338 96.9653 80.8778 99.9017 82.668Z"
                                        fill="#D78100" />
                                    <path
                                        d="M115.029 59.5286L113.027 58.2738C112.984 58.2125 112.896 58.0898 112.958 58.0462L113.019 58.0026C113.08 57.9589 113.203 57.8717 113.247 57.933L115.248 59.1878C115.292 59.2491 115.379 59.3718 115.317 59.4154L115.256 59.4591C115.238 59.564 115.134 59.5463 115.029 59.5286Z"
                                        fill="#D78100" />
                                    <path
                                        d="M112.204 59.3216L109.757 57.8298C109.652 57.8121 109.626 57.6458 109.687 57.6022L109.767 57.4536C109.784 57.3486 109.95 57.3227 109.994 57.384L112.441 58.8759C112.546 58.8936 112.572 59.0599 112.511 59.1035L112.432 59.2521C112.414 59.357 112.309 59.3393 112.204 59.3216Z"
                                        fill="#D78100" />
                                    <path
                                        d="M109.396 59.656L106.949 58.1641C106.8 58.0851 106.818 57.9802 106.897 57.8316L107.037 57.6394C107.116 57.4908 107.221 57.5085 107.37 57.5875L109.817 59.0794C109.965 59.1584 109.948 59.2633 109.869 59.4119L109.728 59.6041C109.606 59.6914 109.439 59.7173 109.396 59.656Z"
                                        fill="#D78100" />
                                    <path
                                        d="M106.471 60.9985L103.832 59.3663C103.683 59.2873 103.701 59.1824 103.78 59.0338L103.92 58.8415C103.999 58.6929 104.104 58.7106 104.253 58.7896L106.892 60.4218C107.04 60.5009 107.023 60.6058 106.944 60.7544L106.803 60.9466C106.742 60.9903 106.576 61.0162 106.471 60.9985Z"
                                        fill="#D78100" />
                                    <path
                                        d="M104.142 62.6564L101.31 60.8839C101.162 60.8049 101.179 60.6999 101.258 60.5513L101.399 60.3591C101.478 60.2105 101.583 60.2282 101.731 60.3072L104.563 62.0798C104.711 62.1588 104.693 62.2637 104.614 62.4123L104.474 62.6045C104.351 62.6918 104.185 62.7178 104.142 62.6564Z"
                                        fill="#D78100" />
                                    <path
                                        d="M102.151 64.5346L99.3629 62.8233C99.2143 62.7443 99.2319 62.6394 99.311 62.4908L99.4513 62.2986C99.5303 62.15 99.6353 62.1676 99.7838 62.2466L102.572 63.9579C102.72 64.0369 102.703 64.1419 102.623 64.2904L102.483 64.4826C102.361 64.5699 102.299 64.6136 102.151 64.5346Z"
                                        fill="#D78100" />
                                    <path
                                        d="M100.545 66.6937L97.7574 64.9825C97.6088 64.9035 97.6265 64.7985 97.7055 64.6499L97.8458 64.4577C97.9249 64.3091 98.0298 64.3268 98.1784 64.4059L100.966 66.1171C101.115 66.1961 101.097 66.301 101.018 66.4496L100.878 66.6419C100.86 66.7468 100.694 66.7728 100.545 66.6937Z"
                                        fill="#D78100" />
                                    <path
                                        d="M99.3425 69.0256L96.6597 67.3321C96.5111 67.2531 96.5288 67.1481 96.6078 66.9995L96.7482 66.8073C96.8272 66.6587 96.9322 66.6765 97.0807 66.7555L99.7635 68.449C99.9121 68.528 99.8944 68.633 99.8154 68.7816L99.6751 68.9738C99.5524 69.061 99.3861 69.087 99.3425 69.0256Z"
                                        fill="#D78100" />
                                    <path
                                        d="M98.42 71.6216L95.7808 69.9893C95.6322 69.9103 95.6499 69.8054 95.7289 69.6568L95.8693 69.4646C95.9483 69.316 96.0532 69.3336 96.2018 69.4127L98.841 71.0449C98.9896 71.1239 98.9719 71.2289 98.8929 71.3774L98.7526 71.5697C98.6912 71.6133 98.5249 71.6392 98.42 71.6216Z"
                                        fill="#D78100" />
                                    <path
                                        d="M98.0901 74.5365L95.3023 72.8253C95.1537 72.7463 95.1714 72.6413 95.2504 72.4927L95.3908 72.3005C95.4698 72.1519 95.5747 72.1696 95.7233 72.2486L98.5111 73.9599C98.6597 74.0389 98.642 74.1438 98.563 74.2924L98.4226 74.4846C98.3613 74.5283 98.195 74.5542 98.0901 74.5365Z"
                                        fill="#D78100" />
                                </g>
                                <path d="M264.121 199.864L290.02 183.699L285.675 176.283L258.965 191.058L264.121 199.864Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M265.162 197.489C265.162 195.171 263.54 192.39 261.512 191.289C259.542 190.131 257.92 191.115 257.92 193.375C257.92 195.693 259.542 198.474 261.57 199.575C263.598 200.733 265.162 199.806 265.162 197.489Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M263.658 198.358C263.658 196.04 262.036 193.259 260.008 192.158C258.038 191 256.416 191.985 256.416 194.244C256.416 196.562 258.038 199.343 260.066 200.444C262.094 201.603 263.716 200.676 263.658 198.358Z"
                                    fill="#E2DCFF" />
                                <path d="M228.371 220.838L262.671 200.734L257.515 191.927L223.215 212.09L228.371 220.838Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M229.414 218.52C229.414 216.202 227.792 213.421 225.764 212.321C223.794 211.162 222.172 212.147 222.172 214.406C222.172 216.724 223.794 219.505 225.822 220.606C227.85 221.765 229.414 220.838 229.414 218.52Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M228.141 217.768C228.083 216.436 226.982 214.756 225.65 214.002C224.317 213.249 223.39 213.771 223.448 215.161C223.506 216.494 224.607 218.174 225.939 218.927C227.214 219.68 228.199 219.159 228.141 217.768Z"
                                    fill="#553DB4" />
                                <path
                                    d="M265.395 197.374C265.395 195.057 263.773 192.276 261.745 191.175C260.934 190.711 260.181 190.595 259.602 190.769C260.239 190.074 261.34 190.016 262.499 190.711C264.468 191.87 266.149 194.593 266.149 196.911C266.149 198.301 265.569 199.17 264.7 199.402C265.164 198.939 265.453 198.243 265.395 197.374Z"
                                    fill="white" />
                                <path
                                    d="M265.394 197.372C265.394 195.055 263.772 192.274 261.744 191.173C260.759 190.594 259.832 190.536 259.194 190.941L257.225 192.1L258.152 193.664C258.325 195.75 259.716 198.126 261.454 199.284L262.381 200.849L264.351 199.69C265.046 199.342 265.452 198.531 265.394 197.372Z"
                                    fill="#553DB4" />
                                <path
                                    d="M263.483 198.531C263.483 196.213 261.86 193.432 259.833 192.331C257.863 191.173 256.24 192.157 256.24 194.417C256.24 196.735 257.863 199.516 259.89 200.617C261.918 201.717 263.483 200.79 263.483 198.531Z"
                                    fill="#E2DCFF" />
                                <path
                                    d="M226.517 218.81L224.316 215.044L217.073 219.331C216.03 219.969 215.683 221.301 216.32 222.344C216.957 223.387 218.29 223.735 219.333 223.098L226.517 218.81Z"
                                    fill="#FFAD00" />
                                <path
                                    d="M226.982 217.826C226.982 216.841 226.287 215.625 225.418 215.161C224.549 214.698 223.854 215.103 223.854 216.088C223.854 217.073 224.549 218.29 225.418 218.753C226.287 219.217 226.982 218.811 226.982 217.826Z"
                                    fill="#FFAD00" />
                                <path
                                    d="M235.034 204.441L233.933 203.804C233.296 203.456 232.485 203.514 231.616 204.035L227.907 206.237C227.212 206.643 226.633 207.628 226.633 208.497V210.351C226.633 210.641 226.459 211.046 226.285 211.278L226.401 212.147L225.474 211.626C225.358 211.568 225.242 211.394 225.242 211.162V209.308C225.242 207.628 226.401 205.542 227.907 204.673L231.616 202.471C232.89 201.718 234.049 201.602 234.918 202.123L236.019 202.761C236.656 203.108 237.467 203.051 238.336 202.529L253.401 193.664C253.574 193.548 253.748 193.548 253.922 193.606L254.849 194.128L254.038 194.418C253.922 194.765 253.69 195.055 253.401 195.229L238.336 204.093C237.12 204.847 235.903 204.963 235.034 204.441Z"
                                    fill="#553DB4" />
                                <path
                                    d="M234.861 204.384C234.223 204.037 233.412 204.094 232.543 204.616L228.835 206.818C228.14 207.223 227.56 208.208 227.56 209.077V210.931C227.56 211.395 227.271 211.916 226.865 212.148C226.46 212.38 226.17 212.206 226.17 211.742V209.888C226.17 208.208 227.329 206.122 228.835 205.253L232.543 203.052C233.818 202.298 234.977 202.182 235.846 202.704L236.947 203.341C237.584 203.689 238.395 203.631 239.264 203.11L254.328 194.245C254.734 194.013 255.024 194.187 255.024 194.65C255.024 195.114 254.734 195.635 254.328 195.867L239.264 204.732C237.99 205.485 236.831 205.601 235.962 205.079L234.861 204.384Z"
                                    fill="#553DB4" />
                                <path
                                    d="M227.504 209.367V211.221V211.279C227.562 211.163 227.562 211.047 227.562 210.931V209.077C227.562 209.019 227.562 208.961 227.562 208.903C227.562 209.019 227.504 209.193 227.504 209.367Z"
                                    fill="white" />
                            </g>
                            <defs>
                                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(186.841 137.58) rotate(180) scale(59.6649)">
                                    <stop stop-color="#553DB4" />
                                    <stop offset="1" stop-color="#182484" />
                                </radialGradient>
                                <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(212 111) rotate(106.26) scale(37.5)">
                                    <stop stop-color="#1E1348" />
                                    <stop offset="1" stop-color="#553DB4" />
                                </radialGradient>
                                <linearGradient id="paint2_linear" x1="94.538" y1="218.276" x2="179.642" y2="67.4806"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#E2DCFF" />
                                    <stop stop-color="#E2DCFF" />
                                    <stop offset="0.0001" stop-color="white" />
                                    <stop offset="0.0933267" stop-color="white" />
                                    <stop offset="0.1341" stop-color="#FAF9FF" />
                                    <stop offset="0.1829" stop-color="white" />
                                    <stop offset="0.2117" stop-color="#FBFAFF" />
                                    <stop offset="0.256" stop-color="#FBFAFF" />
                                    <stop offset="0.3145" stop-color="#F3F0FF" />
                                    <stop offset="0.3869" stop-color="#F1EEFF" />
                                    <stop offset="0.5143" stop-color="#F1EEFF" />
                                    <stop offset="0.6239" stop-color="#F5F3FF" />
                                    <stop offset="0.755" stop-color="#FAF9FF" />
                                    <stop offset="1" stop-color="#E2DCFF" />
                                </linearGradient>
                                <linearGradient id="paint3_linear" x1="119.286" y1="118.876" x2="100.366" y2="118.876"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C0D6FF" />
                                    <stop offset="0.4983" stop-color="#D2E8FF" />
                                    <stop offset="1" stop-color="#E9FFFF" />
                                </linearGradient>
                                <linearGradient id="paint4_linear" x1="140.49" y1="118.876" x2="103.927" y2="118.876"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5B45B0" />
                                    <stop offset="1" stop-color="#E4DEFD" />
                                </linearGradient>
                                <linearGradient id="paint5_linear" x1="119.286" y1="91.582" x2="100.366" y2="91.582"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C0D6FF" />
                                    <stop offset="0.4983" stop-color="#D2E8FF" />
                                    <stop offset="1" stop-color="#E9FFFF" />
                                </linearGradient>
                                <linearGradient id="paint6_linear" x1="140.49" y1="91.582" x2="103.927" y2="91.582"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5B45B0" stop-opacity="0.54" />
                                    <stop offset="1" stop-color="#E3DDFD" />
                                </linearGradient>
                                <linearGradient id="paint7_linear" x1="156.225" y1="134.721" x2="119.663" y2="134.721"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5B45B0" />
                                    <stop offset="1" stop-color="#E4DEFD" />
                                </linearGradient>
                                <linearGradient id="paint8_linear" x1="150.753" y1="136.857" x2="131.833" y2="136.857"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C0D6FF" />
                                    <stop offset="0.4983" stop-color="#D2E8FF" />
                                    <stop offset="1" stop-color="#E9FFFF" />
                                </linearGradient>
                                <linearGradient id="paint9_linear" x1="171.957" y1="136.857" x2="135.394" y2="136.857"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5B45B0" />
                                    <stop offset="1" stop-color="#E4DEFD" />
                                </linearGradient>
                                <linearGradient id="paint10_linear" x1="166.486" y1="149.622" x2="147.566" y2="149.622"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C0D6FF" />
                                    <stop offset="0.4983" stop-color="#D2E8FF" />
                                    <stop offset="1" stop-color="#E9FFFF" />
                                </linearGradient>
                                <linearGradient id="paint11_linear" x1="187.689" y1="149.622" x2="151.127" y2="149.622"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5B45B0" />
                                    <stop offset="1" stop-color="#E5DFFD" />
                                </linearGradient>
                                <linearGradient id="paint12_linear" x1="182.218" y1="162.835" x2="163.298" y2="162.835"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C0D6FF" />
                                    <stop offset="0.4983" stop-color="#D2E8FF" />
                                    <stop offset="1" stop-color="#E9FFFF" />
                                </linearGradient>
                                <linearGradient id="paint13_linear" x1="203.422" y1="162.835" x2="166.859" y2="162.835"
                                    gradientUnits="userSpaceOnUse">
                                    <stop offset="0.275629" stop-color="#5B45B0" />
                                    <stop offset="1" stop-color="#E9E5FF" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="main-width">
        <div class="row no-gutters">
            <router-link class="btn btn-info w-100 py-3 mt-5" style="font-size:42px" :to="{ name: 'monopoly' }">
                Информация для субъектов естественных монополий
            </router-link>
       </div>
        <div class="row no-gutters">
           <router-link class="btn btn-info w-100 py-3 mt-5" style="font-size:42px" :to="{ name: 'tbpage' }">
                Список товарных бирж интегрированных с eoz.kz
            </router-link>
        </div>
        <div class="row no-gutters">
          <router-link class="btn btn-info w-100 py-3 mt-5" style="font-size:42px" :to="{ name: 'stimul' }">
                Меры государственного стимулирования промышленности
          </router-link>
        </div>
        <div class="row no-gutters">
            <div v-show="getLanguage() === 'ru'">
                <router-link :to="{ name: 'platforms' }">
                    <img src="@/assets/img/platforms-ru.svg" class="img-fluid mt-5">
                </router-link>
                <router-link class="d-block mb-3" :to="{ name: 'region' }">
                    <img src="@/assets/img/regions-ru.svg" class="img-fluid mt-5">
                </router-link>
            </div>
            <div v-show="getLanguage() === 'kz'">
                <router-link :to="{ name: 'platforms' }">
                    <img src="@/assets/img/platforms-kz.svg" class="img-fluid mt-5">
                </router-link>
                <router-link class="d-block mb-3" :to="{ name: 'region' }">
                    <img src="@/assets/img/regions-kz.svg" class="img-fluid mt-5">
                </router-link>
            </div>

        </div>
    </section>
    <section class="main-width mt-5">
        <div class="row no-gutters">
            <div class="col-12 col-md-6 col-lg-4">
                <div class="service">
                    <div class="service__img">
                        <img src="@/assets/img/icons/service1.svg" alt="">
                    </div>
                    <div class="service__body">
                        <h2> {{$t('default.search_tender')}} </h2>
                        <p>{{$t('default.search_five_places')}}</p>
                        <router-link :to="{ name: 'tenders' }">{{ $t("default.additional") }} &nbsp;&nbsp; &#x279C;</router-link>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="service">
                    <div class="service__img odd">
                        <img src="@/assets/img/icons/service2.svg" alt="">
                    </div>
                    <div class="service__body">
                        <h2> {{$t('default.price_analytics')}} </h2>
                        <p>{{$t('default.price_analytics_txt')}}</p>
                        <a href="/landing/land_ru.html">{{ $t("default.additional") }} &nbsp;&nbsp; &#x279C;</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="service">
                    <div class="service__img ">
                        <img src="@/assets/img/icons/service3.svg" alt="">
                    </div>
                    <div class="service__body">
                        <h2> {{$t('default.customer_info')}} </h2>
                        <p>{{$t('default.customer_info_txt')}}</p>
                        <home-modal />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="service">
                    <div class="service__img odd">
                        <img src="@/assets/img/icons/service4.svg" alt="">
                    </div>
                    <div class="service__body">
                        <h2> {{$t('default.notification_new')}} </h2>
                        <p>{{$t('default.notification_new_txt')}}</p>
                        <router-link :to="{ name: 'notifications' }">{{ $t("default.additional") }} &nbsp;&nbsp; &#x279C;</router-link>
                        <!-- <a href="/old.html#!/cabinet/notifications">{{ $t("default.additional") }} &nbsp;&nbsp; &#x279C;</a> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="service">
                    <div class="service__img">
                        <img src="@/assets/img/icons/service5.svg" alt="">
                    </div>
                    <div class="service__body">
                        <h2>{{$t('default.favourite')}}</h2>
                        <p>{{$t('default.favourite_txt')}}</p>
                        <router-link :to="{ name: 'favourite' }">{{ $t("default.additional") }} &nbsp;&nbsp; &#x279C;</router-link>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="service">
                    <div class="service__img odd">
                        <img src="@/assets/img/icons/service6.svg" alt="">
                    </div>
                    <div class="service__body">
                        <h2>{{$t('default.rules')}}</h2>
                        <p>{{$t('default.rules_txt')}}</p>
                        <router-link :to="{ name: 'documents' }">{{ $t("default.additional") }} &nbsp;&nbsp; &#x279C;</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="main-width mt-5">
        <div class="row no-gutters">
            <div class="col-12 col-md-6">
                <div class="main-info bg--yellow">
                    <h2>{{$t('default.actual')}}</h2>
                    <h1> {{sumCount | sum}} </h1>
                    <p>{{sum}} ₸</p>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="main-info bg-white">
                    <h2> {{$t('mainPage.allSum')}} </h2>
                    <div class="main-info__content">
                        <div 
                            v-for="price in prices"
                            :key="price.id"
                        >
                            <p>{{ price.amount | sum }} ₸</p>
                            <a href="#">{{ price.count | sum }} {{price.nameRu}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="main-width mt-5">
        <h2 class="package__title text-center">{{$t('mainPage.packForUsers')}}</h2>
        <div class="row no-gutters price-area">

          <div class="col-12 col-sm-4 col-lg-4">
                <div class="price">
                    <h2 class="price__title">{{$t('mainPage.individual')}}</h2>
                    <p class="price__desc">&nbsp;</p>
                    <div class="price__content ">
                        <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                            <p>{{$t('mainPage.individual_desc')}}</p>
                        </div>
                    </div>
                  <div class="price__price">
                    <h3 class="price__cost">{{$t('mainPage.individual_price')}}</h3>
                    <p style="opacity: 0" class="price__cost-desc">тенге</p>
                    <a href="#" class="price__btn" @click.prevent="makeCall()">{{$t('mainPage.individual_call')}}</a>
                    <a style="opacity: 0"  data-v-3cacfa1e="" href="#" class="price__link">Посмотреть демонстрацию</a>
                  </div>
                </div>
            </div>

          <div class="col-12 col-sm-4 col-lg-4">
            <div class="price">
              <h2 class="price__title">Аудитор</h2>
              <!--                      <h2 class="price__title">{{$t('mainPage.individual')}}</h2>-->
              <p class="price__desc">В зависимости от объемов закупок заказчика, цена пакета варьируется:</p>
              <div style="padding-bottom: 33px;" class="price__content ">
                <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                  <p>До 50 млн. тенге - 250 000 тенге</p>
                </div>
                <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                  <p>
                    от 50 млн. тенге до 100 млн. тенге -
                    <br>
                    500 000 тенге
                  </p>
                </div>
                <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                  <p>
                    от 100 млн. тенге  до 500 млн. тенге -
                    <br>
                    2 500 000 тенге
                  </p>
                </div>
                <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                  <p>
                    от 500 млн. тенге  до 1 млрд. тенге -
                    <br>
                    5 000 000 тенге
                  </p>
                </div>
              </div>
              <div class="price__price">
                <p class="price__cost-desc" style="opacity: 0">{{$t('mainPage.tenders_price')}}</p>
                <a href="#" class="price__btn" @click.prevent="makeCall()">{{$t('mainPage.individual_call')}}</a>
                <a style="opacity: 0"  data-v-3cacfa1e="" href="#" class="price__link">Посмотреть демонстрацию</a>
              </div>
            </div>
          </div>
            <div class="col-12 col-sm-4 col-lg-4" v-for="t in tariffs" :key="t.id">
                <div class="price">
                    <template v-if="$i18n.locale== 'kz'">
                        <h2 class="price__title">{{ t.nameKz }}</h2>
                        <p class="price__desc">{{ t.contentDescriptionKz }}</p>
                        <div class="price__content ">
                            <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                <p>{{ t.contentKz1 }}</p>
                            </div>
                            <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                <p>{{ t.contentKz2 }}</p>
                            </div>
                            <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                <p>{{ t.contentKz3 }}</p>
                            </div>
                            <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                <p>{{ t.contentKz4 }}</p>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <h2 class="price__title">{{ t.nameRu }}</h2>
                        <p class="price__desc">{{ t.contentDescriptionRu }}</p>
                        <div class="price__content ">
                            <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                <p>{{ t.contentRu1 }}</p>
                            </div>
                            <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                <p>{{ t.contentRu2 }}</p>
                            </div>
                            <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                <p>{{ t.contentRu3 }}</p>
                            </div>
                            <div><img src="@/assets/img/icons/package_arrow.png" alt="">
                                <p>{{ t.contentRu4 }}</p>
                            </div>
                        </div>
                    </template>
                    <div class="price__price">
                        <h3 class="price__cost">{{ t.price | sum }}</h3>
                        <p class="price__cost-desc">{{$t('mainPage.tenders_price')}}</p>
                        <a href="#" class="price__btn" @click.prevent="makeOrder(t)">{{$t('mainPage.tenders_btn')}}</a>
                        <a href="#" class="price__link" @click.prevent="openUrl(t.url)">{{$t('mainPage.tenders_link')}}</a>
                    </div>
                </div>
            </div>

        </div>
    </section>

  </div>

</template>

<script>
import HomeModal from '../components/app/HomeModal'
import mixOrder from '@/mixins/order'

export default {
    mixins: [mixOrder],
    metaInfo() {
        return {
            title: this.$title('Домашняя страница')
        }
    },
    components: {
        HomeModal, 
    },
  name: 'Home',
  data: () => ({
      keywords: null,
      searchOption: "tenders",
      prices: null,
      allSum: null,
      sumCount: null
  }),
  mounted() {
      this.getSums()
  },
  methods: {
    makeCall() {
        this.$root.$emit('callback-show');
    },
        searchKeywords() {
            this.$router.push({name: this.searchOption, query: { keywords: this.keywords }});
        },
      getSums() {
        this.$store.dispatch('GET_PRICES')
        .then(res => {
            this.prices = res.content[0].members
        })
        
        this.$store.dispatch('GET_ALL_SUM')
            .then(res => {
                this.allSum = res.todayTenderAmount
                this.sumCount = res.todayTenderCount
            })
      },
      getLanguage() {
          return this.$i18n.locale;
      },
  },
  computed: {
    sum() {
        if (this.allSum) {
            let number = (this.allSum).toFixed()
            
            if (number.length >= 13) {
                return (number/1000000000000).toFixed(2) + ' трлн';
            } else if (number.length >= 10) {
                return Math.ceil(number/1000000000) + ' млрд';
            } else if (number.length >= 7) {
                return (number/1000000).toFixed(1) + ' млн';
            } else {
                return (number/1000).toFixed(1) + ' тсч';
            }

        } return ''
    }
  }
}
</script>
